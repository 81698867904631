import actionTypes from "./actionTypes";
import getNestedShallowCopy from "../../utils/getnestedShallowCopy";
import getUniqueId from "../../utils/uniqueId";

/*
State structure
{
  errorId: {
    data: Object,
    type: Enum<String> ['network'],
    message: String,
    timestamp: Date,
    resolved: Boolean
  }
}
 */

const initialState = {};

const reducer = (state = initialState, action) => {
  const { payload } = action;
  switch (action.type) {
    case actionTypes.ADD:
      return addNewError(state, payload);
    case actionTypes.SET_RESOLVED:
      return setResolved(state, payload);
    default:
      return state;
  }
};

const addNewError = (state, payload) => {
  const { data, type, message, timestamp, resolved, id } = payload;
  // If payload miss required properties error is not created
  if (!message || !type) {
    return state;
  }
  const dynamicProperties = {
    errorId: id || getUniqueId("Error")
  };
  return getNestedShallowCopy(state, {
    path: "errorId",
    payload: {
      timestamp: timestamp || new Date(),
      resolved: resolved || false,
      data,
      type,
      message
    },
    dynamicProperties
  });
};

const setResolved = (state, payload) => {
  const dynamicProperties = {
    errorId: payload
  };
  return getNestedShallowCopy(state, {
    path: "errorId.resolved",
    payload: true,
    dynamicProperties
  });
};

export default reducer;
