export const isToday = (date) => {
  const d = date instanceof Date ? date : new Date(date);
  const today = new Date().setUTCHours(0,0,0,0);
  return today === d.setUTCHours(0,0,0,0);
}

export const getDayTimeZero = (date) => {
  const d = date instanceof Date ? date : new Date(date);
  const dayTimeZero = d.setUTCHours(0,0,0,0);
  return new Date(dayTimeZero);
}
