import React, { useState } from 'react';
import PropTypes from 'prop-types';
import H2 from '../UI/Headers/H2';
import CityItem from './CityItem';
import styles from './CitiesList.module.css';

const CitiesList = ({
  data,
  onSelect,
  className,
  style,
  ...restActionHandlers
}) => {
  const cities = data ? [].concat(data) : [];
  const [itemSelected, selectItem] = useState(null)

  const onSelectHandler = (id) => {
    onSelect(id);
    selectItem(id);
  }

  const onKeyDownHandler = (e) => {
    let action;
    switch(e.key) {
      case 'ArrowUp':
        e.preventDefault();
        action = -1;
        break;
      case 'ArrowDown':
        e.preventDefault();
        action = 1;
        break;
      default:
        return;
    }
    onSelectHandler(getAdjacentId(action));
  }

  const getAdjacentId = (newPos) => {
    if (itemSelected === null) return cities[0].id;
    const cityIndex = cities.findIndex(city => city.id === itemSelected);
    let newIndex = cityIndex + newPos;
    if (newIndex < 0 ) newIndex = cities.length - 1;
    if (newIndex === cities.length ) newIndex = 0;
    return cities[newIndex].id;
  }

  const cityItems = cities.map((city) => {
    return (
      <CityItem
        {...restActionHandlers}
        key={city.id}
        city={city}
        onSelect={(id) => onSelectHandler(id)}
        selected={itemSelected === city.id}
      />
    );
  });
  const containerClasses = [styles.Container];
  if (className) {
    containerClasses.unshift(className)
  }
  return (
    <article className={containerClasses.join(' ')} style={style}>
      <H2>Locations</H2>
      <ul
        onKeyDown={(e) => onKeyDownHandler(e)}
        tabIndex="-1"
        className={styles.List}
      >
        {cityItems}
      </ul>
    </article>
  );
}

CitiesList.propTypes = {
  data: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string.isRequired
    })),
    PropTypes.shape({
      id: PropTypes.string.isRequired
    })
  ]),
  onSelect: PropTypes.func.isRequired,
  className: PropTypes.string,
  style: PropTypes.object,
}
export default CitiesList;
