import React, { useCallback, useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import useErrorListener from "../../hooks/errorsListener";
import { actions } from "../../store";

const errorTypes = {
  QUERY: "QUERY",
  SET_ON_MAP: "SET_ON_MAP",
  LOCATION_INFO: "LOCATIONS_INFO"
};

const LocManager = props => {
  const dispatch = useDispatch();
  const { errors, tryDispatch, resolve } = useErrorListener();
  const { className, style, children } = props;

  //Local state
  const [query, setQuery] = useState("");
  const [selected, setSelected] = useState({
    id: null,
    locationName: null,
    weatherAreaId: null
  });
  const [loadingWAs, setLoadingWAs] = useState([]);

  //State from store
  const queriesMap = useSelector(store => store.queries.map);
  const storeLocations = useSelector(store => store.locations);

  //Manage errors
  const errorTrackers = useRef({});

  const untrackErrorById = useCallback(
    errId => {
      if (!errId) return;
      delete errorTrackers.current[errId];
      return resolve(errId);
    },
    [resolve]
  );

  const untrackError = useCallback(
    matcher => {
      const { current } = errorTrackers;
      const errId = Object.keys(current).find(errId => {
        return (
          current[errId].type === matcher.type &&
          current[errId].payload === matcher.payload
        );
      });
      if (errId) return untrackErrorById(errId);
    },
    [untrackErrorById]
  );

  const resolveQueryError = useCallback(
    errId => {
      const { current } = errorTrackers;
      if (query === current[errId].payload) {
        setQuery(""); //NOTE search component input value will remain untouched
        untrackErrorById(errId).sendMessage();
      } else {
        untrackErrorById(errId);
      }
    },
    [query, untrackErrorById]
  );

  const resolveSetOnMapError = useCallback(
    errId => {
      const { current } = errorTrackers;
      const locId = current[errId].payload;
      dispatch(actions.locations.toggleWeather(locId, false));
      setLoadingWAs(prev => prev.filter(val => val !== locId));
      untrackErrorById(errId).sendMessage();
    },
    [dispatch, untrackErrorById]
  );

  const resolveLocationInfoError = useCallback(
    errId => {
      const { current } = errorTrackers;
      const locId = current[errId].payload;
      if (selected.id === locId) {
        untrackErrorById(errId).sendMessage();
        setSelected({
          id: null,
          locationName: null,
          weatherAreaId: null
        });
      } else {
        untrackErrorById(errId);
      }
    },
    [selected, untrackErrorById]
  );

  useEffect(() => {
    const { current } = errorTrackers;
    Object.keys(errors).forEach(errId => {
      switch (current[errId]?.type) {
        case errorTypes.QUERY:
          resolveQueryError(errId);
          break;
        case errorTypes.SET_ON_MAP:
          resolveSetOnMapError(errId);
          break;
        case errorTypes.LOCATION_INFO:
          resolveLocationInfoError(errId);
          break;
        default:
        //Do nothing
      }
    });
  }, [
    errors,
    untrackErrorById,
    resolveQueryError,
    resolveSetOnMapError,
    resolveLocationInfoError
  ]);

  //Search by query
  const shouldSearch =
    query.length > 0 && !queriesMap[query] && Object.keys(errors).length === 0;
  const searching = query.length > 0 && queriesMap[query]?.loading;

  useEffect(() => {
    if (shouldSearch) {
      const id = tryDispatch(actions.queries.searchLocations, query);
      errorTrackers.current[id] = { type: errorTypes.QUERY, payload: query };
    }
  }, [query, shouldSearch, tryDispatch]);

  const handleSearch = useCallback(query => {
    setQuery(query);
  }, []);

  let searchResults;
  if (queriesMap[query] && !searching) {
    untrackError({ type: errorTypes.QUERY, payload: query });
    searchResults = queriesMap[query].locations.map(id => ({
      id,
      description: storeLocations[id].description
    }));
  }

  const handleSubmit = useCallback(
    id => {
      setQuery("");
      dispatch(actions.locations.useLocation(id));
    },
    [dispatch]
  );

  //Location handlers
  const removeLocation = useCallback(
    id => {
      dispatch(actions.locations.removeLocation(id));
      setSelected({
        id: null,
        locationName: null,
        weatherAreaId: null
      });
    },
    [dispatch]
  );

  const toggleLocationSaved = useCallback(
    id => {
      const isSaved = storeLocations[id].isSaved;
      dispatch(actions.locations.setIsSaved(id, !isSaved));
    },
    [storeLocations, dispatch]
  );

  const toggleLocationOnMap = useCallback(
    id => {
      const isOnMap = storeLocations[id].isOnMap;
      const errorId = tryDispatch(
        actions.locations.toggleWeather,
        id,
        !isOnMap
      );
      errorTrackers.current[errorId] = {
        type: errorTypes.SET_ON_MAP,
        payload: id
      };
      setLoadingWAs(prev => [...prev, id]);
    },
    [storeLocations, tryDispatch]
  );

  const onSelectLocation = useCallback(
    id => {
      const { name, weatherAreaId, geometry } = storeLocations[id];
      setSelected({
        id,
        locationName: name,
        weatherAreaId: weatherAreaId || ""
      });
      if (!weatherAreaId) {
        const errorId = tryDispatch(
          actions.weatherAreas.addToLocation,
          id,
          geometry
        );
        errorTrackers.current[errorId] = {
          type: errorTypes.LOCATION_INFO,
          payload: id
        };
      }
    },
    [storeLocations, tryDispatch]
  );

  useEffect(() => {
    const { id, weatherAreaId: oldWAId } = selected;
    if (storeLocations[id]?.weatherAreaId && !oldWAId) {
      const { weatherAreaId } = storeLocations[id];
      setSelected(prev => ({ ...prev, weatherAreaId }));
      untrackError({ type: errorTypes.LOCATION_INFO, payload: id });
    }
  }, [selected, storeLocations, untrackError]);

  const locations = Object.keys(storeLocations).reduce((acc, id) => {
    if (storeLocations[id].onlyCached) return acc;
    const {
      name,
      description: desc,
      weatherAreaId,
      isSaved,
      isOnMap
    } = storeLocations[id];
    //Do not listen for errors if weatherAreaId finished loading
    if (weatherAreaId && loadingWAs.includes(id)) {
      untrackError({ type: errorTypes.SET_ON_MAP, payload: id });
      setLoadingWAs(prev => prev.filter(val => val !== id));
    }
    return [
      ...acc,
      {
        id,
        name,
        desc,
        isSaved,
        isOnMap,
        weatherAreaId: loadingWAs.includes(id) ? "" : weatherAreaId
      }
    ];
  }, []);

  return (
    <section className={className} style={style}>
      {children({
        handleSearch,
        handleSubmit,
        searchResults,
        searching,
        removeLocation,
        toggleLocationSaved,
        toggleLocationOnMap,
        onSelectLocation,
        selectedLocationName: selected.locationName,
        selectedWeatherAreaId: selected.weatherAreaId,
        locations
      })}
    </section>
  );
};

LocManager.propTypes = {
  children: PropTypes.func.isRequired,
  style: PropTypes.object,
  className: PropTypes.string
};
export default LocManager;
