import React from 'react';
import styles from './Backdrop.module.css';

const Backdrop = ({onClick, show}) => {
  if (show) {
    return (
      <div
        onClick={onClick}
        className={styles.Backdrop}
        role="presentation none"
      >
      </div>
    );
  }
  return null;
};

export default Backdrop;
