import {
  createStore,
  combineReducers,
  applyMiddleware,
  compose as c
} from "redux";
import ReduxThunk from "redux-thunk";
import locations from "./locations";
import weatherAreas from "./weatherAreas";
import errors from "./errors";
import messages from "./messages";
import queries from "./queries";
import thunks from "./thunks";

const reducers = combineReducers({
  locations: locations.reducer,
  weatherAreas: weatherAreas.reducer,
  queries: queries.reducer,
  errors: errors.reducer,
  messages: messages.reducer
});

// Exposes only action creators required outside store
const locActions = locations.actions;
const waActions = weatherAreas.actions;
const qActions = queries.actions;
const eActions = errors.actions;
const mActions = messages.actions;
export const actions = {
  locations: {
    removeLocation: locActions.removeLocation,
    useLocation: locActions.useLocation,
    toggleWeather: locActions.toggleWeather,
    setIsSaved: locActions.setIsSaved
  },
  weatherAreas: {
    update: waActions.update,
    addToLocation: waActions.addToLocation
  },
  queries: {
    searchLocations: qActions.searchLocations
  },
  errors: {
    add: eActions.add,
    setResolved: eActions.setResolved
  },
  messages: {
    add: mActions.add,
    remove: mActions.remove
  },
  //Global action creators (return thunks)
  showPointData: (lon, lat, errorTracker) => (dispatch, getState) => {
    thunks.showingPointData(
      dispatch,
      getState,
      { lon, lat },
      {
        setWeatherArea: locActions.setWeatherArea,
        addLocation: locActions.add,
        setLocationOnlyCached: locActions.setOnlyCached,
        setLocationOnMap: locActions.setOnMap,
        addNewWeatherArea: waActions.addNew,
        addError: errors.actions.add
      },
      errorTracker
    );
  }
};

let store;
if (process.env.NODE_ENV !== "production") {
  const compose = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || c;
  store = createStore(reducers, compose(applyMiddleware(ReduxThunk)));
} else {
  store = createStore(reducers, applyMiddleware(ReduxThunk));
}

export default store;
