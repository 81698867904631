import React from 'react';
import PropTypes from 'prop-types';
import MutableButton from '../UI/Buttons/MutableButton';
import ScreenLoader from '../UI/ScreenLoader';
import styles from './DateHandler.module.css';

const DateHandler = (props) => {
  const { dates, dayHandler } = props;
  let currentDate;
  if (dates) {
    //Format Date
    const month = Intl.DateTimeFormat('en', { month: 'short' }).format(dates.current);
    const day = Intl.DateTimeFormat('en', { day: '2-digit' }).format(dates.current);
    const weekday = Intl.DateTimeFormat('en', { weekday: 'short' })
      .format(dates.current);
    currentDate = (
      <time dateTime={dates.current.toISOString()}>
        {`${weekday} ${day} ${month}`}
      </time>
    );
  } else {
    currentDate = (
      <ScreenLoader className={styles.Loader} />
    );
  }
  const containerClasses = [styles.Container];
  if (props.className) {
    containerClasses.unshift(props.className);
  }
  return (
    <nav className={containerClasses.join(' ')} style={props.style}>
      <MutableButton
        className={styles.Button}
        disabled={!dates || dates.current <= dates.min}
        onAction={() => dayHandler(-1)}
        mode='left'
      />
      <h3 className={styles.Header}>
        {currentDate}
      </h3>
      <MutableButton
        className={styles.Button}
        disabled={!dates || dates.current >= dates.max}
        onAction={() => dayHandler(1)}
        mode='right'
      />
    </nav>
  );
}

//Custom validators
const isLaterThanMinProp = (props, propName) => {
  // Prop.dates is not required
  if (!props.dates) return;
  const validatingProp = props.dates[propName];
  //Validate if is instance of Date
  if (!(validatingProp instanceof Date )) {
    return new Error(`${validatingProp} should be an instace of Date`);
  }
  //Validate if is bigger than min
  if (validatingProp <= props.dates.min) {
    return new Error(`${validatingProp} should be bigger than min`);
  }
}

const isEqualOrBetweenMinAndMaxProps = (props, propName) => {
  // Prop.dates is not required
  if (!props.dates) return;
  const validatingProp = props.dates[propName];
  //Validate if is instance of Date
  if (!(validatingProp instanceof Date )) {
    return new Error(`${validatingProp} should be an instace of Date`);
  }
  //Validate if is equal or between max and min props
  if (validatingProp < props.dates.min || validatingProp > props.dates.max) {
    return new Error(`${validatingProp} should be equal or between max and min props`);
  }
}

DateHandler.propTypes = {
  dates: PropTypes.exact({
    min: PropTypes.instanceOf(Date).isRequired,
    max: isLaterThanMinProp,
    current: isEqualOrBetweenMinAndMaxProps,
  }),
  dayHandler: PropTypes.func.isRequired,
  className: PropTypes.string,
  style: PropTypes.object
}
export default DateHandler;
