import React from "react";
import { useSelector, useDispatch } from "react-redux";
import Message from "../../components/Message";
import { actions } from "../../store";

const Messages = props => {
  const dispatch = useDispatch();
  const messages = useSelector(store => store.messages);

  const handleClose = id => {
    dispatch(actions.messages.remove(id));
  };

  const msgsMarkup = messages.map(msg => {
    const { id, message, type, duration } = msg;
    return (
      <Message
        key={id}
        mode={type}
        duration={duration}
        close={() => handleClose(id)}
      >
        {message}
      </Message>
    );
  });

  const inlineStyles = {
    display: messages.length > 0 ? "flex" : "none",
    flexDirection: "column"
  };

  return (
    <div className={props.className} style={inlineStyles}>
      {msgsMarkup.reverse()}
    </div>
  );
};

export default Messages;
