/**
 * Useful for arrays to find an index based on selected one and the distance. If distance goes out of array bounds, it returns the min or max postion or loop through depending on param loop
 * @param  {Number}  currentIndex Current selected Index
 * @param  {Number}  distance     Distance to the current selected Index, negative numbers indicates distance to the left and positive to the right.
 * @param  {Number}  length       Length of array, note that the number is not inclusive: las position = length - 1
 * @param  {Boolean} [loop=false] If false returns min or max position when distance goes out of bounds. If true it loops through
 * @return {Number}               New array position
 */

const getRelativeIndex = (currentIndex, distance, length, loop = false)  => {
  let result = currentIndex + distance;
  if (result >= 0 && result < length ) return result;
  if (!loop && result < 0) return 0;
  if (!loop && result >= length) return length - 1;
  result = result % length;
  return result >= 0 ? result : result + length;
}

export default getRelativeIndex;
