import { polygonCentroid, polygonContains, polygonArea } from "d3-polygon";

export const getCentroid = geometry => {
  switch (geometry.type.toLowerCase()) {
    case "polygon":
      return polygonCentroid(geometry.coordinates[0]);
    case "multipolygon":
      //Centroid might be outside of multipolygon area
      const weightedCentroid = geometry.coordinates.reduce(
        (acc, polygon) => {
          const { centroidAcc, totalWeight } = acc;
          /*
          GeoJSON Polygon spec: Array of LinearRings, Polygons with multiple rings, the first must be the exterior ring and any others must be interior rings or holes.
          NOTE: The code below assumes polygons have no "holes" and only first linear ring is considered (polygon[0])
           */
          const centroid = polygonCentroid(polygon[0]);
          const weight = Math.abs(polygonArea(polygon[0]));
          return {
            centroidAcc: [
              centroidAcc[0] + centroid[0] * weight,
              centroidAcc[1] + centroid[1] * weight
            ],
            totalWeight: totalWeight + weight
          };
        },
        { centroidAcc: [0, 0], totalWeight: 0 }
      );
      const { centroidAcc, totalWeight } = weightedCentroid;
      return [centroidAcc[0] / totalWeight, centroidAcc[1] / totalWeight];
    default:
      return geometry.coordinates;
  }
};

export const contains = (geometry, point) => {
  switch (geometry.type.toLowerCase()) {
    case "polygon":
      return polygonContains(geometry.coordinates[0], point);
    case "multipolygon":
      return geometry.coordinates.some(polygon =>
        /*
        GeoJSON Polygon spec: Array of LinearRings, Polygons with multiple rings, the first must be the exterior ring and any others must be interior rings or holes.
        NOTE: The code below assumes polygons have no "holes" and only first linear ring is considered (polygon[0])
         */
        polygonContains(polygon[0], point)
      );
    default:
      return false;
  }
};
