import React, { useState, useRef } from "react";
import PropTypes from "prop-types";
import reseters from "./BasicButton.module.css";

const BasicButton = ({
  children,
  title,
  styles,
  stylesEnabled,
  stylesActive,
  stylesDisabled,
  onAction,
  disabled
}) => {
  const [active, setActive] = useState(false);
  const btnRef = useRef();

  const btnClasses = [reseters.Reseters];
  let style = {};
  if (typeof styles === "string") {
    btnClasses.push(styles);
  } else {
    style = { ...styles };
  }
  if (typeof stylesActive === "string" && active) {
    btnClasses.push(stylesActive);
  } else if (active) {
    style = { ...style, ...stylesActive };
  }
  if (typeof stylesDisabled === "string" && disabled) {
    btnClasses.push(stylesDisabled);
  } else if (typeof stylesDisabled === "object" && disabled) {
    style = { ...style, ...stylesDisabled };
  }
  if (typeof stylesEnabled === "string" && !disabled) {
    btnClasses.push(stylesEnabled);
  } else if (typeof stylesEnabled === "object" && !disabled) {
    style = { ...style, ...stylesEnabled };
  }

  const keyHandler = e => {
    if (!(e.key === "Enter" || e.key === "Space") || disabled) return;
    e.preventDefault();
    if (e.type === "keydown") {
      setActive(true);
    } else {
      setActive(false);
      onActionHandler(e);
    }
  };

  const onActionHandler = e => {
    if (disabled) return;
    e.preventDefault();
    btnRef.current.blur();
    onAction(e);
  };

  return (
    <button
      ref={btnRef}
      className={btnClasses.join(" ")}
      style={style}
      title={title}
      onClick={onActionHandler}
      onKeyDown={keyHandler}
      onKeyUp={keyHandler}
    >
      {children}
    </button>
  );
};

BasicButton.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired,
  title: PropTypes.string,
  styles: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
  stylesEnabled: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  stylesActive: PropTypes.oneOfType([PropTypes.object, PropTypes.string])
    .isRequired,
  stylesDisabled: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  disabled: PropTypes.bool.isRequired,
  onAction: PropTypes.func.isRequired
};

BasicButton.defaultProps = {
  disabled: false
};

export default BasicButton;
