import React from 'react';
import PropTypes from 'prop-types';
import styles from './H2.module.css';

const H2 = (props) => {
  const containerClasses = [styles.Container]
  if(props.className) {
    containerClasses.push(props.className);
  }
  return (
    <header className={containerClasses.join(' ')} style={props.style}>
      <h2 className={styles.Header}>
       {props.children}
      </h2>
    </header>
  );
}

H2.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired,
  className: PropTypes.string,
  style: PropTypes.object
}

export default H2;
