import getUniqueId from "../../utils/uniqueId";

const adding = (dispatch, getState, payload, actions) => {
  const { message, type, duration } = payload;
  const { push, pop } = actions;
  const id = getUniqueId("message");
  const timerId = setTimeout(() => dispatch(pop(id)), duration);
  dispatch(push(message, type, duration, id, timerId));
};

const removing = (dispatch, getState, payload, actions) => {
  const state = getState();
  const message = state.messages.find(msg => msg.id === payload);
  if (message) {
    clearTimeout(message.timerId);
    dispatch(actions.pop(payload));
  }
};

const thunks = { adding, removing };

export default thunks;
