/**
 * Pure function that returns a copy of a data structure (root) with
 * payload inserted in a specific path
 * The function creates a shallow copy for root and also for all pointers
 * included in path
 * It leaves the rest of root nodes untoched. The copy will have
 * references to those pointers
 * If path doesn't exist in rootObject, it will be created in the copy
 * If there is a node in root for the path, It will be overrided by
 * payload in the copy
 * If payload is Nil the last node of the path will be deleted
 * @param  {T = Object || Array } root Data to be copied with new payload
 * @param  {Object || Array} newData Object or array of objects with the following fields:
 * - {String} path Path inside new copy to point to payload
 * - {Any} payload Data to be merged with root to create the new copy
 * - {Object} dynamicProperties Path properties that are dynamic cannot be inserted using template literals as that could brake the parser if they include dots, numbers or square brackets. The keys of the object indicate the name of the property in the path parameter string and the values the new dynamic field. i.e. Path: myObject.myDiynamicKey.myOtherProperty, dynamicProperties: { myDynamicKey: 'propertyinserted' }, will result in the path myObject.propertyinserted.myOtherProperty
 * @return {T} shallow copy of root with path pointing to payload on which all nodes of the path are new or shallow copies of same node in root
 */
const getNestedShallowCopy = (root, newData) =>
  [].concat(newData).reduce((root, { path, payload, dynamicProperties }) => {
    if (!path) {
      return _getShallowCopy(root);
    }
    //Parse path string
    const dotsSplitted = path.split(".");
    const pathKeys = dotsSplitted.flatMap(str => {
      return str.split(/\[(.+?)\]/g).filter(val => val !== "");
    });

    //Build new copy
    return pathKeys.reduceRight((acc, key, i, arr) => {
      const parentPath = arr.slice(0, i).map(key => {
        return dynamicProperties?.[key] || key;
      });
      /*
      The second parameter (key) of _getShallowCopy is used to indicate when the parent is not defined in root if parent should be an array or an object.
      When parent is unknown, if key === '0'. it will create an empty array otherwise an empty object.
      Important!!. do not replace dynamicKey in key argument. To avoid creating an array if dynamicKey value is 0 (Note that arrays do not have dynamic keys, a diynamic index could be inserted in the path using template literals)
       */
      const node = _getShallowCopy(_getNodePointer(root, parentPath), key);
      const finalKey = dynamicProperties?.[key] || key;
      /*
      If payload is Nil, the object property that points to it, is deleted. In the case that payload is Nil inside an array. The item at the index is removed .
       */
      if (acc === undefined || acc === null) {
        if (Array.isArray(node)) {
          node.splice(finalKey, 1);
        } else {
          delete node[finalKey];
        }
      } else {
        node[finalKey] = acc;
      }
      return node;
    }, payload);
  }, root);

//Returns pointer following pathArray in data
const _getNodePointer = (data, pathArray) => {
  return pathArray.reduce((acc, key) => {
    return acc?.[key];
  }, data);
};

//Return a shallow copy of argument
const _getShallowCopy = (value, nextKey) => {
  /*
  If value is undefined, path must be created, so that if nextKey is 0 an array will bre created. Otherwise an object
   */
  if (value === undefined && nextKey === "0") {
    return [];
  } else if (value === undefined) {
    return {};
  } else if (Array.isArray(value)) {
    return [...value];
  } else if (Object(value) === value) {
    return { ...value };
  }
  const newVal = value; //Assumes is primitive
  return newVal;
};

// Generic function that allows to insert more than one payload.
//const getNestedShallowCopy = (root, path, payload, dynamicProperties)

export default getNestedShallowCopy;
