/**
 * The function checks that the focus is not in
 * the target or its children.
 * @param {BlurEvent} e
 * @return {boolean} Returns true if none of the target's children or
 *  the target has focus
 */
const blurOnTree = function(target, focusedElement) {
  if (focusedElement === null) return true;

  let node = focusedElement;

  while (node !== null) {
    if (node === target) return false;
    node = node.parentNode;
  }

  return true;
}

export default blurOnTree;
