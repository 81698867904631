import actionTypes from "./actionTypes";
import getNestedShallowCopy from "../../utils/getnestedShallowCopy";

const initialState = {
  cache: [],
  map: {}
};

const reducer = (state = initialState, action) => {
  const { payload } = action;
  switch (action.type) {
    case actionTypes.ADD_QUERY:
      return addQuery(state, payload);
    case actionTypes.ADD_LOCATIONS_LIST:
      return addLocationsList(state, payload);
    case actionTypes.BUMP_QUERY:
      return bumpQuery(state, payload);
    case actionTypes.DELETE_QUERY:
      return deleteQuery(state, payload);
    default:
      return state;
  }
};

const addQuery = (state, payload) => {
  const { cache } = state;
  return getNestedShallowCopy(state, [
    {
      path: `cache[${cache.length}]`,
      payload
    },
    {
      path: "map.payload.loading",
      payload: true,
      dynamicProperties: { payload }
    }
  ]);
};

const addLocationsList = (state, payload) => {
  const { query, locationIds } = payload;
  const dynamicProperties = { query };
  return getNestedShallowCopy(state, [
    {
      path: "map.query.locations",
      payload: locationIds,
      dynamicProperties
    },
    {
      path: "map.query.loading",
      payload: false,
      dynamicProperties
    }
  ]);
};

const bumpQuery = (state, payload) => {
  const { cache } = state;
  return {
    ...state,
    cache: [...cache.filter(query => query !== payload), payload]
  };
};

const deleteQuery = (state, payload) => {
  return getNestedShallowCopy(state, [
    { path: `cache[${state.cache.indexOf(payload)}]` },
    { path: "map.payload", dynamicProperties: { payload } }
  ]);
};

export default reducer;
