import React, { useState, useEffect, createRef, useRef } from "react";
import Spinner from "../UI/Spinner";
import { IconContext } from "react-icons";
import { FaSearch, FaMapMarkerAlt, FaChevronUp } from "react-icons/fa";
import PropTypes from "prop-types";
import styles from "./Search.module.css";
import blurOnTree from "../../utils/blurOnTree";
import useMessages from "../../hooks/messages";

const Search = props => {
  const { searching, data, onSearch, onSubmit } = props;
  const foundLocations = data ? [].concat(data) : [];
  const numFoundLoc = foundLocations.length;
  const sendMessage = useMessages();
  const [inputValue, setInputValue] = useState("");
  const [selectedLocIndex, setSelectedLocIndex] = useState(
    numFoundLoc > 0 ? 1 : null
  );
  const locationRefs = useRef(
    Array(numFoundLoc)
      .fill()
      .map((_, i) => createRef())
  );
  const inputSearchRef = useRef(null);

  if (numFoundLoc !== locationRefs.current.length) {
    const { current } = locationRefs;
    locationRefs.current = Array(numFoundLoc)
      .fill()
      .map((_, i) => current[i] || createRef());
  }

  // Handle search requests
  useEffect(() => {
    if (inputValue.length >= 3) {
      const timeout = setTimeout(() => {
        onSearch(inputValue);
      }, 1000);
      return () => clearTimeout(timeout);
    } else {
      setSelectedLocIndex(null);
    }
  }, [onSearch, inputValue]);

  // Handle location references and location list rendering
  useEffect(() => {
    if (numFoundLoc === 0) {
      setSelectedLocIndex(null);
    } else {
      setSelectedLocIndex(prev => (prev !== null ? prev : 0));
    }
  }, [numFoundLoc]);

  // Controlled input handler
  const onInputValueChanged = event => {
    setInputValue(event.target.value);
  };

  const handleLocationSubmit = () => {
    setInputValue("");
    onSubmit(locationRefs.current[selectedLocIndex].current.dataset.location);
  };

  const hideLocations = e => {
    if (e.preventDefault) e.preventDefault();
    setSelectedLocIndex(null);
    inputSearchRef.current.focus();
  };

  const showLocations = e => {
    if (e.preventDefault) e.preventDefault();
    setSelectedLocIndex(0);
    inputSearchRef.current.focus();
  };

  const selectLocation = (e, i) => {
    setSelectedLocIndex(prev => (prev !== null ? i : null));
  };

  const handleKeyDown = (e, i) => {
    switch (e.key) {
      // Submit location Id or show locations
      case "Enter":
        if (selectedLocIndex !== null) {
          e.preventDefault();
          handleLocationSubmit();
        } else if (numFoundLoc !== 0) {
          showLocations(e);
        }
        break;
      // Focus next result
      case "ArrowDown":
        if (numFoundLoc === 0) return;
        setSelectedLocIndex(prev => {
          if (prev === null || prev === numFoundLoc - 1) {
            return 0;
          } else {
            return prev + 1;
          }
        });
        break;
      // Focus prev result
      case "ArrowUp":
        if (numFoundLoc === 0) return;
        setSelectedLocIndex(prev => {
          if (prev === null || prev === 0) {
            return numFoundLoc - 1;
          } else {
            return prev - 1;
          }
        });
        break;
      case "Escape":
        if (selectedLocIndex !== null) hideLocations(e);
        break;
      default:
    }
  };

  //Send message if query has no locations
  const wasSearching = useRef(searching);
  useEffect(() => {
    if (data && data.length === 0 && wasSearching.current && !searching) {
      sendMessage(`I couldn't find ${inputValue}`);
    }
    if (searching) {
      wasSearching.current = true;
    } else {
      wasSearching.current = false;
    }
  }, [data, sendMessage, inputValue, searching]);

  // Locations list options
  const resultsStyles = [styles.Results];
  if (selectedLocIndex === null) {
    resultsStyles.push(styles.Hidden);
  }

  // Location elements
  let foundLocElements;
  if (numFoundLoc) {
    foundLocElements = foundLocations.map((val, i) => {
      const classes = [styles.ResultItem];
      if (i === selectedLocIndex) {
        classes.push(styles.SelectedItem);
      }
      return (
        <li
          tabIndex={selectedLocIndex === null ? "-1" : "0"}
          className={classes.join(" ")}
          key={val.id}
          data-location={val.id}
          ref={locationRefs.current[i]}
          onClick={handleLocationSubmit}
          onMouseEnter={e => selectLocation(e, i)}
          onFocus={e => selectLocation(e, i)}
        >
          <IconContext.Provider value={{ className: styles.ResultIcon }}>
            <FaMapMarkerAlt />
          </IconContext.Provider>
          {val.description}
        </li>
      );
    });
  }

  // Input button
  let button;
  if (selectedLocIndex !== null) {
    button = (
      <button
        className={styles.Button}
        onClick={hideLocations}
        title="Hide found locations"
        tabIndex="-1"
      >
        <FaChevronUp />
      </button>
    );
  } else {
    button = (
      <button
        className={styles.Button}
        onClick={showLocations}
        disabled={numFoundLoc === 0}
        title="Show Found locations"
        tabIndex="-1"
      >
        <FaSearch />
      </button>
    );
  }

  const containerClasses = [styles.Input];
  if (props.className) {
    containerClasses.unshift(props.className);
  }
  return (
    <form
      role="search"
      className={containerClasses.join(" ")}
      style={props.style}
      onKeyDown={handleKeyDown}
      onBlur={e =>
        blurOnTree(e.currentTarget, e.relatedTarget)
          ? setSelectedLocIndex(null)
          : null
      }
    >
      <input
        type="search"
        placeholder="Search Location..."
        className={styles.Search}
        value={inputValue}
        onChange={onInputValueChanged}
        ref={inputSearchRef}
      />
      {searching ? <Spinner className={styles.Spinner} /> : button}
      <ul title="Results List" className={resultsStyles.join(" ")}>
        {foundLocElements}
      </ul>
    </form>
  );
};

Search.propTypes = {
  searching: PropTypes.bool.isRequired,
  data: PropTypes.arrayOf(
    PropTypes.exact({
      id: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired
    })
  ),
  onSearch: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  style: PropTypes.object,
  className: PropTypes.string
};

Search.defaultProps = {
  searching: false
};

export default Search;
