import React from "react";
import PropTypes from "prop-types";
import { scaleLinear } from "@visx/scale";
import { IconContext } from "react-icons";
import { FiSunrise, FiSunset } from "react-icons/fi";
import { RiTempHotLine } from "react-icons/ri";
import { BsFillPersonFill } from "react-icons/bs";
import { WiDayCloudy } from "react-icons/wi";
import { GiWindsock } from "react-icons/gi";
import { HiOutlineArrowNarrowUp } from "react-icons/hi";
import WeatherIcon from "../../WeatherIcon";
import H3 from "../../UI/Headers/H3";
import styles from "./DayInfo.module.css";

const DayInfo = ({ data, className, style }) => {
  const timeFormat = new Intl.DateTimeFormat("en", {
    timeStyle: "short",
    timeZone: "Europe/Dublin"
  });
  const tempToColorScale = scaleLinear()
    .domain([4, 20, 36])
    .range(["#6b6bff", "#35b52a", "#fe6e6e"]);
  const windToColorScale = scaleLinear()
    .domain([25, 62, 100])
    .range(["#35b52a", "#c9a628", "#fe6e6e"]);
  const cloudToColorScale = scaleLinear()
    .domain([0, 100])
    .range(["#35b52a", "#6b6bff"]);
  const containerClasses = [styles.Container];
  if (className) {
    containerClasses.unshift(className);
  }
  return (
    <section className={containerClasses.join(" ")} style={style}>
      <div
        className={styles.Sunrise}
        title={`Sunrise: ${timeFormat.format(data.sunrise)}`}
      >
        <IconContext.Provider value={{ style: { verticalAlign: "baseline" } }}>
          <FiSunrise />
        </IconContext.Provider>
        &nbsp;{timeFormat.format(data.sunrise)}
      </div>
      <div
        className={styles.Sunset}
        title={`Sunset: ${timeFormat.format(data.sunset)}`}
      >
        <IconContext.Provider value={{ style: { verticalAlign: "baseline" } }}>
          <FiSunset />
        </IconContext.Provider>
        &nbsp;{timeFormat.format(data.sunset)}
      </div>
      <div
        className={styles.Temp}
        title={`Average temperature: ${data.temp.avg} ºC`}
        style={{ color: tempToColorScale(data.temp.avg) }}
      >
        <IconContext.Provider value={{ style: { verticalAlign: "middle" } }}>
          <RiTempHotLine />
        </IconContext.Provider>
        &nbsp;{data.temp.avg} ºC
      </div>
      <div
        className={styles.FeelTemp}
        title={`Feels like: ${data.temp.feelsLike} ºC`}
        style={{ color: tempToColorScale(data.temp.feelsLike) }}
      >
        <IconContext.Provider value={{ style: { verticalAlign: "baseline" } }}>
          <BsFillPersonFill />
        </IconContext.Provider>
        &nbsp;{data.temp.feelsLike} ºC
      </div>
      <div
        className={styles.Clouds}
        title={`Clouds:  ${data.clouds || 0}%`}
        style={{ color: cloudToColorScale(data.clouds || 0) }}
      >
        <WiDayCloudy /> {data.clouds}%
      </div>
      <div
        className={styles.Wind}
        title={`Wind speed: ${data.wind.speed} km/h, direction: ${data.wind.deg} Degrees`}
        style={{ color: windToColorScale(data.wind.speed) }}
      >
        <span>
          <GiWindsock />
        </span>
        &nbsp;{data.wind.speed} km/h&nbsp;
        <span
          className={styles.WindArrow}
          style={{ transform: `rotate(${data.wind.deg}deg)` }}
        >
          <HiOutlineArrowNarrowUp />
        </span>
      </div>
      <div
        className={styles.Description}
        title={`${data.main.main}, ${data.main.description}`}
      >
        <H3>{data.main.main}</H3>
        <p>{data.main.description}</p>
      </div>
      <div className={styles.Logo}>
        <WeatherIcon iconType={data.main.icon} width={48} height={48} />
      </div>
    </section>
  );
};

DayInfo.propTypes = {
  data: PropTypes.exact({
    sunset: PropTypes.instanceOf(Date),
    sunrise: PropTypes.instanceOf(Date),
    clouds: PropTypes.number,
    temp: PropTypes.shape({
      avg: PropTypes.number,
      feelsLike: PropTypes.number
    }),
    wind: PropTypes.shape({
      deg: PropTypes.number,
      speed: PropTypes.number
    }),
    main: PropTypes.shape({
      main: PropTypes.string,
      description: PropTypes.string,
      icon: PropTypes.string
    })
  }).isRequired,
  className: PropTypes.string,
  style: PropTypes.object
};

export default DayInfo;
