import axios from "axios";
import { sortAndMapLocations, mapWeatherArea } from "./dataMaps";

axios.defaults.baseURL = process.env.REACT_APP_BASE_URL;
axios.defaults.validateStatus = status => status === 200;

const backend = {
  searchLocationByQuery: query => {
    return axios
      .get(`/loc/query/${encodeURIComponent(query)}`)
      .then(res => sortAndMapLocations(res.data))
      .catch(e => {
        if (e.response?.data.error.code === 404) return []; //If not found returns empty array
        throw e;
      });
  },
  searchLocationByCoords: (lon, lat) => {
    return axios
      .get(`/loc/query/${lat},${lon}`)
      .then(res => sortAndMapLocations(res.data));
  },
  getWeatherByCoords: (lon, lat) => {
    return axios
      .get(`/weather/coords/${lat},${lon}`)
      .then(res => mapWeatherArea(res.data));
  },
  getAllByCoords: (lon, lat) => {
    return axios.get(`/coords/${lat},${lon}`).then(res => {
      return {
        location: sortAndMapLocations(res.data.location)[0],
        weatherArea: mapWeatherArea(res.data.weatherArea)
      };
    });
  }
};

export default backend;
