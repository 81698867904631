import actionTypes from "./actionTypes";
import reducer from "./reducer";
import thunks from "./thunks";

/* Action creators that return actions */
const push = (message, type, duration, id, timerId) => {
  return {
    type: actionTypes.PUSH,
    payload: { message, type, duration, id, timerId }
  };
};

const pop = id => {
  return {
    type: actionTypes.POP,
    payload: id
  };
};

/* Action creators that return a thunk */
const add = (message, type, duration) => (dispatch, getState) => {
  thunks.adding(dispatch, getState, { message, type, duration }, { push, pop });
};

const remove = id => (dispatch, getState) => {
  thunks.removing(dispatch, getState, id, { pop });
};

const actions = { push, pop, add, remove };
const messages = { actions, reducer };

export default messages;
