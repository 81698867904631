import { getCentroid, contains } from "../../utils/geometryHelpers";
import backend from "../../backend";

const updatingWeatherArea = (
  dispatch,
  getState,
  weatherAreaId,
  actions,
  errorTracker
) => {
  const { updateWeather, setLoading, addError } = actions;
  dispatch(setLoading(weatherAreaId, true));
  const wa = getState().weatherAreas[weatherAreaId];
  const coords = getCentroid(wa.geometry);
  return backend
    .getWeatherByCoords(...coords)
    .then(weatherArea => dispatch(updateWeather(weatherArea, errorTracker)))
    .catch(e => {
      dispatch(
        addError({
          data: e.response?.data,
          type: "network",
          message: e.response
            ? "There was an error in the server, try it later"
            : "I can't reach the server. Internet connection? proxy? firewall?...",
          id: errorTracker
        })
      );
    })
    .finally(() => dispatch(setLoading(weatherAreaId, false)));
};

const addingToLocation = (
  dispatch,
  getState,
  payload,
  actions,
  errorTracker
) => {
  const { locationId, geometry } = payload;
  const { setLocationWeatherArea, addNew, addError } = actions;
  const centroid = getCentroid(geometry);
  const { weatherAreas } = getState();
  const weatherAreaId = Object.keys(weatherAreas).find(waKey =>
    contains(weatherAreas[waKey].geometry, centroid)
  );
  if (weatherAreaId) {
    dispatch(setLocationWeatherArea(locationId, weatherAreaId, errorTracker));
    return;
  }
  return backend
    .getWeatherByCoords(...centroid)
    .then(weatherArea => {
      dispatch(addNew(weatherArea, errorTracker));
      dispatch(
        setLocationWeatherArea(locationId, weatherArea.id, errorTracker)
      );
    })
    .catch(e => {
      dispatch(
        addError({
          data: e.response?.data,
          type: "network",
          message: e.response
            ? "There was an error in the server, try it later"
            : "I can't reach the server. Internet connection? proxy? firewall?...",
          id: errorTracker
        })
      );
    });
};

const thunks = {
  updatingWeatherArea,
  addingToLocation
};

export default thunks;
