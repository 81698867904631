import React, { useState } from "react";
import MapFrame from "./containers/MapFrame";
import DateHandler from "./components/DateHandler";
import Map from "./components/Map";
import SideDrawer from "./components/SideDrawer";
import BasicButton from "./components/UI/Buttons/BasicButton";
import Modal from "./components/UI/Modal";
import Messages from "./containers/Messages";
import About from "./static/About";
import styles from "./App.module.css";

function App() {
  const [showAbout, setShowAbout] = useState();
  return (
    <>
      <div className={styles.App}>
        <MapFrame className={styles.MainView}>
          {props => (
            <>
              <Map
                className={styles.Map}
                date={props.selectedDate}
                onAction={props.onAction}
                icons={props.icons}
              />
              <DateHandler
                className={styles.DateHandler}
                dates={props.datesRange}
                dayHandler={props.dayHandler}
              />
            </>
          )}
        </MapFrame>
        <SideDrawer className={styles.SideDrawer} />
      </div>
      <BasicButton
        title="About this page"
        styles={styles.AboutBtn}
        stylesActive={styles.AboutBtnActive}
        onAction={() => setShowAbout(true)}
      >
        About...
      </BasicButton>
      {showAbout && (
        <Modal show close={() => setShowAbout(false)}>
          <About />
        </Modal>
      )}
      <Messages className={styles.Messages} />
    </>
  );
}

export default App;
