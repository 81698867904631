import { contains } from "../utils/geometryHelpers";
import backend from "../backend";

const showingPointData = (
  dispatch,
  getState,
  payload,
  actions,
  errorTracker
) => {
  const {
    addNewWeatherArea,
    addLocation,
    setWeatherArea,
    setLocationOnMap,
    setLocationOnlyCached,
    addError
  } = actions;
  const { lon, lat } = payload;
  const { weatherAreas, locations } = getState();
  const weatherAreaId = Object.keys(weatherAreas).find(key =>
    contains(weatherAreas[key].geometry, [lon, lat])
  );
  const locationId = Object.keys(locations).find(key =>
    contains(locations[key].geometry, [lon, lat])
  );
  const _getError = e => ({
    data: e.response?.data,
    type: "network",
    message: e.response
      ? "There was an error in the server, try it later"
      : "I can't reach the server. Internet connection? proxy? firewall?...",
    id: errorTracker
  });
  if (locationId === undefined && weatherAreaId === undefined) {
    return backend
      .getAllByCoords(lon, lat)
      .then(res => {
        const { weatherArea, location } = res;
        dispatch(addNewWeatherArea(weatherArea, errorTracker));
        dispatch(
          addLocation(
            {
              ...location,
              weatherAreaId: weatherArea.id,
              onlyCached: false,
              isOnMap: true
            },
            errorTracker
          )
        );
      })
      .catch(e => {
        dispatch(addError(_getError(e)));
      });
  } else if (locationId !== undefined && weatherAreaId === undefined) {
    return backend
      .getWeatherByCoords(lon, lat)
      .then(weatherArea => {
        dispatch(addNewWeatherArea(weatherArea, errorTracker));
        dispatch(setWeatherArea(locationId, weatherArea.id, errorTracker));
        dispatch(setLocationOnMap(locationId, true, errorTracker));
      })
      .catch(e => {
        dispatch(addError(_getError(e)));
      });
  } else if (locationId === undefined && weatherAreaId !== undefined) {
    return backend
      .searchLocationByCoords(lon, lat)
      .then(location => {
        dispatch(
          addLocation(
            {
              ...location,
              weatherAreaId: weatherAreaId,
              onlyCached: false,
              isOnMap: true
            },
            errorTracker
          )
        );
      })
      .catch(e => {
        dispatch(addError(_getError(e)));
      });
  }
  //If reach this point Location and weather-area are in the store
  dispatch(setWeatherArea(locationId, weatherAreaId, errorTracker));
  dispatch(setLocationOnMap(locationId, true, errorTracker));
  dispatch(setLocationOnlyCached(locationId, false, errorTracker));
};

const thunks = {
  showingPointData
};

export default thunks;
