import actionTypes from "./actionTypes";
import reducer from "./reducer";
import thunks from "./thunks";
import locs from "../locations";
import errors from "../errors";

/* Action creators that return actions */
const addNew = weatherArea => {
  return {
    type: actionTypes.ADD_NEW,
    payload: weatherArea
  };
};

const setLoading = (weatherAreaId, isLoading) => {
  return {
    type: actionTypes.SET_LOADING,
    payload: { weatherAreaId, isLoading }
  };
};

const updateWeather = weatherArea => {
  return {
    type: actionTypes.UPDATE_WEATHER,
    payload: weatherArea
  };
};

/* Action creators that return thunks */

const update = (weatherAreaId, errorTracker) => (dispatch, getState) => {
  thunks.updatingWeatherArea(
    dispatch,
    getState,
    weatherAreaId,
    {
      updateWeather,
      setLoading,
      addError: errors.actions.add
    },
    errorTracker
  );
};

const addToLocation = (locationId, geometry, errorTracker) => (
  dispatch,
  getState
) => {
  thunks.addingToLocation(
    dispatch,
    getState,
    { locationId, geometry },
    {
      addNew,
      setLocationWeatherArea: locs.actions.setWeatherArea,
      addError: errors.actions.add
    },
    errorTracker
  );
};

const actions = {
  addNew,
  setLoading,
  updateWeather,
  update,
  addToLocation
};
const weatherAreas = { actions, reducer };

export default weatherAreas;
