import actionTypes from "./actionTypes";
import reducer from "./reducer";
import thunks from "./thunks";
import was from "../weatherAreas";

/* Action creators that return actions */
const add = locations => {
  return {
    type: actionTypes.ADD,
    payload: locations
  };
};

const deleteLocation = locationId => {
  return {
    type: actionTypes.DELETE_LOCATION,
    payload: locationId
  };
};

const setIsSaved = (id, isSaved) => {
  return {
    type: actionTypes.SET_IS_SAVED,
    payload: { locationId: id, isSaved }
  };
};

const setOnMap = (id, isOnMap) => {
  return {
    type: actionTypes.SET_ON_MAP,
    payload: { locationId: id, isOnMap }
  };
};

const setTracker = (locationId, trackerValue) => {
  return {
    type: actionTypes.SET_QUERY_TRACKER,
    payload: { locationId, queriesTracker: trackerValue }
  };
};

const setOnlyCached = (locationId, onlyCached) => {
  return {
    type: actionTypes.SET_ONLY_CACHED,
    payload: { locationId, onlyCached }
  };
};

const setWeatherArea = (locationId, weatherAreaId) => {
  return {
    type: actionTypes.SET_WEATHER_AREA,
    payload: { locationId, weatherAreaId }
  };
};

/* Action creators that return a thunk */
const updateQueryTrackers = (locationIds, increment, errorTracker) => (
  dispatch,
  getState
) => {
  thunks.updatingQueryTrackers(
    dispatch,
    getState,
    { locationIds, addToTracker: increment },
    { setTracker, deleteLocation },
    errorTracker
  );
};

const removeLocation = (locationId, errorTracker) => (dispatch, getState) => {
  thunks.removingLocation(
    dispatch,
    getState,
    locationId,
    {
      setOnlyCached,
      deleteLocation
    },
    errorTracker
  );
};

const toggleWeather = (locationId, isOnMap, errorTracker) => (
  dispatch,
  getState
) => {
  thunks.togglingWeather(
    dispatch,
    getState,
    { locationId, isOnMap },
    {
      setOnMap,
      getWeatherAreaId: was.actions.addToLocation
    },
    errorTracker
  );
};

const useLocation = (locationId, errorTracker) => (dispatch, getState) => {
  thunks.usingLocation(
    dispatch,
    getState,
    locationId,
    { setOnlyCached },
    errorTracker
  );
};

const locations = {
  actions: {
    add,
    setIsSaved,
    setOnMap,
    useLocation,
    setWeatherArea,
    setOnlyCached,
    setTracker,
    deleteLocation,
    updateQueryTrackers,
    removeLocation,
    toggleWeather
  },
  reducer
};

export default locations;
