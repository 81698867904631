import React from 'react';
import PropTypes from 'prop-types';
import Button from '../UI/Buttons/DefaultButton';
import styles from './Question.module.css';
const Question = (props) => {
  const { children, options } = props;
  const clickHandler= (e, cb) => {
    e.preventDefault();
    cb();
  }
  const controls = [].concat(options).map(control => {
    const { text, onAction, format, tooltip } = control;
    return (
      <Button
        key={text}
        type={format || 'Default'}
        onAction={(e) => clickHandler(e, onAction)}
        title={tooltip || text}
      >
        {text}
      </Button>
    )
  });
  return (
    <form>
      <h4>{children}</h4>
      <div className={styles.Controls}>
        {controls}
      </div>
    </form>
  )
}

const optionProps = PropTypes.exact({
  onAction: PropTypes.func.isRequired,
  text: PropTypes.string.isRequired,
  format: PropTypes.oneOf(['Default', 'Danger', 'Highlight']),
  tooltip: PropTypes.string
});

Question.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired,
  options: PropTypes.oneOfType([
    PropTypes.arrayOf(optionProps),
    optionProps
  ]).isRequired
}

export default Question;
