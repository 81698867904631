import React from 'react';
import PropTypes from 'prop-types';
import BasicButton from '../BasicButton';
import styles from './DefaultButton.module.css';

const DefaultButton = ({
  children,
  title,
  type,
  onAction,
  disabled
}) => {
  return (
    <BasicButton
      styles={styles.Button}
      stylesEnabled={styles[type]}
      stylesDisabled={styles.Disabled}
      stylesActive={styles.Active}
      title={title}
      onAction={onAction}
      disabled={disabled}
    >
      {children}
    </BasicButton>
  );
}

DefaultButton.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired,
  title: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['Default', 'Danger', 'Highlight']).isRequired,
  disabled: PropTypes.bool.isRequired,
  onAction: PropTypes.func.isRequired
}

DefaultButton.defaultProps = {
  disabled: false
}

export default DefaultButton;
