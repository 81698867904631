import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import styles from "./Modal.module.css";
import Backdrop from "../Backdrop";

const Modal = ({ show, close, children }) => {
  // useState to render intially a hidden component
  // so that transitions will apply (this might cause one extra render cycle)
  const [firstRenderCycle, setFirstRenderCycle] = useState(true);

  useEffect(() => {
    setFirstRenderCycle(false);
  }, []);

  const containerClasses = [styles.Container];
  const modalClasses = [styles.Modal];
  if (!show || firstRenderCycle) {
    containerClasses.push(styles.Hide);
    modalClasses.push(styles.TranslateUp);
  }

  return (
    <div className={containerClasses.join(" ")} role="presentation none">
      <Backdrop show={show} onClick={close} />
      <section className={modalClasses.join(" ")} role="dialog">
        <button className={styles.CloseBtn} onClick={close}>
          <div className={styles.BtnContent}>&nbsp;</div>
        </button>
        {children}
      </section>
    </div>
  );
};

Modal.propTypes = {
  show: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired
};

export default Modal;
