const actionTypes = {
  ADD: "ADD_LOCATIONS",
  SET_IS_SAVED: "SET_LOCATION_SAVED",
  SET_ON_MAP: "SET_LOCATION_ON_MAP",
  SET_QUERY_TRACKER: "SET_QUERY_TRACKER",
  SET_ONLY_CACHED: "SET_ONLY_CACHED",
  SET_WEATHER_AREA: "SET_LOCATION_WEATHER_AREA",
  DELETE_LOCATION: "DELETE_LOCATION"
};

export default actionTypes;
