// TODO Check touch events
import React from 'react';
import PropTypes from 'prop-types';
import BasicButton from '../BasicButton';
import styles from './MutableButton.module.css';

const MutableButton = ({className, onAction, mode, disabled}) => {

  //Set container classes
  const classesContainer = [styles.MutableButton];
  if (className) {
    classesContainer.push(className);
  }

  //Set content classes
  const classesContent = [styles.MutableButtonContent];
  switch (mode) {
    case 'close':
      classesContent.push(styles.CloseButton);
      classesContent.push( disabled ? styles.Disabled : styles.Danger);
      break;
    case 'left':
      classesContent.push(styles.LeftButton);
      classesContent.push( disabled ? styles.Disabled : styles.Highlight);
      break;
    case 'right':
      classesContent.push(styles.RightButton);
      classesContent.push( disabled ? styles.Disabled : styles.Highlight);
      break;
    default: //burger
      classesContent.push(styles.BurgerButton);
      classesContent.push( disabled ? styles.Disabled : styles.Highlight);
  }

  return (
    <BasicButton
      disabled={disabled}
      onAction={onAction}
      styles={classesContainer.join(' ')}
      stylesActive={styles.Active}
      stylesDisabled={styles.Disabled}
    >
      <span className={classesContent.join(' ')}>&nbsp;</span>
    </BasicButton>
  )
}

MutableButton.propTypes = {
  onAction: PropTypes.func.isRequired,
  mode: PropTypes.oneOf(['burger','close', 'left', 'right']).isRequired,
  className: PropTypes.string,
  disabled: PropTypes.bool.isRequired
}

MutableButton.defaultProps = {
  mode: 'burger',
  disabled: false
}

export default MutableButton;
