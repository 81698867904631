//TODO Fix issues with tooltip: hidden when overflows, pass clasname and styles, mobile touch events
import React from "react";
import PropTypes from "prop-types";
import { scaleBand, scaleLinear, scaleOrdinal } from "@visx/scale";
import { timeFormat } from "d3-time-format";
import { AxisBottom, AxisLeft, AxisRight } from "@visx/axis";
import { Bar } from "@visx/shape";
import { LegendOrdinal, LegendItem, LegendLabel } from "@visx/legend";
import { Group } from "@visx/group";
import { useTooltip, useTooltipInPortal } from "@visx/tooltip";
import { localPoint } from "@visx/event";
import { Text } from "@visx/text";
import styles from "./DaysInfo.module.css";
import Icon from "../../WeatherIcon";

const DaysInfo = props => {
  // Data cleaning and normalization
  const data = props.data
    .sort((a, b) => {
      if (a.date < b.date) return -1;
      if (a.date > b.date) return 1;
      return 0;
    })
    // If snow or rain undefined set to Zero
    .map(d => ({ snow: 0, rain: 0, ...d }));

  // Graph Bounds
  const width = 1000;
  const height = 800;
  const graphTop = 100;
  const graphBottom = 680;
  const graphLeft = 120;
  const graphRight = 860;

  //colors
  const colors = {
    snow: "#c9d8dd",
    rain: "#3963fb",
    temp: "rgba(252, 255, 54, 0.9)"
  };

  // Dates
  const dayScalePadding = 0.05;
  const daysScale = scaleBand({
    domain: data.map(d => d.date),
    range: [graphLeft, graphRight],
    padding: dayScalePadding
  });
  const dayFormat = timeFormat("%d %a");
  const isSelected = date => date.getTime() === props.selected.getTime();

  // Precipitations
  // NOTE: if not rain or snow scale is defined from 0 to 1
  const precMax = data.reduce((acc, d) => {
    const totalPrec = d.rain + d.snow;
    acc = acc > totalPrec ? acc : Math.ceil(totalPrec);
    return acc;
  }, 1);
  const precScale = scaleLinear({
    domain: [0, precMax],
    range: [graphBottom, graphTop],
    nice: true
  });

  // Temperature
  const tempMinMax = Object.values(data).reduce((acc, d) => {
    if (acc[0] === undefined || acc[0] > d.temp.min) {
      acc[0] = Math.floor(d.temp.min);
    }
    if (acc[1] === undefined || acc[1] < d.temp.max) {
      acc[1] = Math.ceil(d.temp.max);
    }
    return acc;
  }, new Array(2));
  const tempScale = scaleLinear({
    domain: tempMinMax,
    range: [graphBottom, graphTop],
    nice: true
  });

  // Bars Tooltip
  const {
    tooltipData,
    tooltipLeft,
    tooltipTop,
    tooltipOpen,
    showTooltip,
    hideTooltip,
    updateTooltip
  } = useTooltip();

  const { containerRef, TooltipInPortal } = useTooltipInPortal({
    // use TooltipWithBounds
    detectBounds: true,
    // when tooltip containers are scrolled, this will correctly update the Tooltip position
    scroll: true
  });

  const handleMouseEnter = (e, d) => {
    const coords = localPoint(e.target.ownerSVGElement, e);
    showTooltip({
      tooltipLeft: coords.x,
      tooltipTop: coords.y,
      tooltipData: d
    });
  };

  const handleMouseMove = (e, d) => {
    const coords = localPoint(e.target.ownerSVGElement, e);
    updateTooltip({
      tooltipLeft: coords.x,
      tooltipTop: coords.y,
      tooltipData: d,
      tooltipOpen: true
    });
  };

  //Legend
  const legendColorScale = scaleOrdinal({
    domain: ["Temp Range", "Rain", "Snow"],
    range: [colors.temp, colors.rain, colors.snow]
  });
  const legendGlyphSize = 15;
  const containerClasses = [styles.Container];
  if (props.className) {
    containerClasses.unshift(props.className);
  }
  return (
    <figure className={containerClasses.join(" ")} style={props.style}>
      <svg
        viewBox={`0 0 ${width} ${height}`}
        className={styles.Diagram}
        ref={containerRef}
      >
        {/* Highlight selected date */}
        <rect
          x={
            daysScale(props.selected) -
            daysScale.bandwidth() * (dayScalePadding / 2)
          }
          y={0}
          height={height}
          width={daysScale.bandwidth() * (1 + dayScalePadding)}
          fill={"var(--color-primary)"}
        />
        {/* Bars & Logo */
        data.map(d => (
          <Group
            key={`bar-${d.date}`}
            onMouseEnter={e => handleMouseEnter(e, d)}
            onMouseMove={e => handleMouseMove(e, d)}
            onMouseOut={hideTooltip}
          >
            <Bar
              x={daysScale(d.date)}
              y={precScale(d.snow)}
              width={daysScale.bandwidth()}
              height={precScale(0) - precScale(d.snow)}
              fill={colors.snow}
              rx={15}
            />
            <Bar
              x={daysScale(d.date)}
              y={precScale(d.rain)}
              width={daysScale.bandwidth()}
              height={precScale(d.snow) - precScale(d.rain)}
              fill={colors.rain}
              rx={15}
            />
            <Bar
              x={daysScale(d.date) + 10}
              y={tempScale(d.temp.max)}
              width={daysScale.bandwidth() - 20}
              height={tempScale(d.temp.min) - tempScale(d.temp.max)}
              fill={colors.temp}
              rx={(daysScale.bandwidth() - 20) / 2}
            />
            <Icon
              iconType={d.main.icon}
              x={daysScale(d.date)}
              y={20}
              width={daysScale.bandwidth()}
              height={daysScale.bandwidth()}
              invColor={isSelected(d.date)}
            />
          </Group>
        ))}
        <AxisBottom
          top={graphBottom}
          strokeWidth={4}
          stroke="var(--color-dark)"
          hideTicks={true}
          scale={daysScale}
          tickFormat={d => dayFormat(d)}
          tickLabelProps={d => ({
            fill: isSelected(d) ? "var(--color-white)" : "var(--color-neutral)",
            fontSize: 50,
            fontWeight: isSelected(d) ? 900 : 400,
            width: 40,
            textAnchor: "middle",
            dy: 50
          })}
        />
        <Text
          angle={-90}
          x={graphLeft}
          dx={-85}
          y={(graphBottom + graphTop) / 2}
          textAnchor="middle"
          fill="var(--color-dark)"
          fontSize={50}
          fontWeight={400}
        >
          mm
        </Text>
        <AxisLeft
          left={graphLeft}
          strokeWidth={4}
          stroke="var(--color-neutral)"
          hideTicks={true}
          tickValues={precScale.ticks(5)}
          scale={precScale}
          tickLabelProps={() => ({
            fill: "var(--color-dark)",
            fontSize: 50,
            fontWeight: 400,
            textAnchor: "middle",
            dx: -30
          })}
        />
        <Text
          x={graphRight}
          dx={100}
          y={(graphBottom + graphTop) / 2}
          textAnchor="middle"
          fill="var(--color-dark)"
          fontSize={50}
          fontWeight={400}
        >
          ºC
        </Text>
        <AxisRight
          left={graphRight}
          strokeWidth={4}
          stroke="var(--color-neutral)"
          hideTicks={true}
          tickValues={tempScale.ticks(5)}
          scale={tempScale}
          tickLabelProps={() => ({
            fill: "var(--color-dark)",
            fontSize: 50,
            fontWeight: 400,
            textAnchor: "middle",
            dx: 35
          })}
        />
      </svg>
      <figcaption className={styles.Caption}>
        <LegendOrdinal scale={legendColorScale}>
          {labels => (
            <div style={{ display: "flex", flexDirection: "row" }}>
              {labels.map((label, i) => (
                <LegendItem key={`legend-quantile-${i}`} margin="0 5px">
                  <svg width={legendGlyphSize} height={legendGlyphSize}>
                    <rect
                      fill={label.value}
                      width={legendGlyphSize}
                      height={legendGlyphSize}
                    />
                  </svg>
                  <LegendLabel align="left" margin="0 0 0 4px">
                    {label.text}
                  </LegendLabel>
                </LegendItem>
              ))}
            </div>
          )}
        </LegendOrdinal>
      </figcaption>
      {tooltipOpen && (
        <TooltipInPortal
          className={styles.Tooltip}
          top={tooltipTop}
          left={tooltipLeft}
          unstyled={true}
        >
          <h4 className={styles.TooltipHeader}>
            {tooltipData.date.toDateString()}
          </h4>
          <p>
            Temp Max:
            <span className={styles.TooltipData}>
              {tooltipData.temp.max} ºC
            </span>
          </p>
          <p>
            Temp Min:
            <span className={styles.TooltipData}>
              {tooltipData.temp.min} ºC
            </span>
          </p>
          {tooltipData.snow ? (
            <p>
              Snow:
              <span className={styles.TooltipData}>{tooltipData.snow} mm</span>
            </p>
          ) : null}
          {tooltipData.rain ? (
            <p>
              Rain:
              <span className={styles.TooltipData}>{tooltipData.rain} mm</span>
            </p>
          ) : null}
        </TooltipInPortal>
      )}
    </figure>
  );
};

DaysInfo.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      date: PropTypes.instanceOf(Date).isRequired,
      temp: PropTypes.shape({
        max: PropTypes.number.isRequired,
        min: PropTypes.number.isRequired
      }),
      main: PropTypes.shape({
        icon: PropTypes.string.isRequired
      }),
      rain: PropTypes.number,
      snow: PropTypes.number
    })
  ).isRequired,
  selected: PropTypes.instanceOf(Date).isRequired,
  className: PropTypes.string,
  style: PropTypes.object
};

export default DaysInfo;
