import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import MutableButton from "../UI/Buttons/MutableButton";
import styles from "./Message.module.css";

const Message = ({ children, title, close, mode, duration }) => {
  const classesContainer = [styles.Container, styles[mode]];
  const [animation, setAnimation] = useState(styles.open);
  const timerIdRef = useRef(null);

  /* Handle animations based on duration prop */
  const animationDuration = duration < 2000 ? Math.round(duration / 10) : 200;
  const initialTime = useRef(Date.now());
  const initStop = useRef(duration - animationDuration);
  useEffect(() => {
    timerIdRef.current = setTimeout(
      () => setAnimation(styles.close),
      initStop.current
    );
    return () => clearTimeout(timerIdRef.current);
  }, []);

  const dynamicStyles = {
    animationDuration: `${animationDuration}ms`
  };

  //Close handler, starts stop animation and execute close porp after it
  const closeHandler = () => {
    //if stop didn't start yet
    if (Date.now() - initialTime.current < initStop.current) {
      clearTimeout(timerIdRef.current);
      setAnimation(styles.close);
      timerIdRef.current = setTimeout(close, animationDuration);
    }
  };

  return (
    <aside
      className={classesContainer.concat(animation).join(" ")}
      style={dynamicStyles}
    >
      {title && <strong>{title}</strong>}
      <span>{children}</span>
      <MutableButton
        className={styles.Button}
        mode="close"
        onAction={closeHandler}
      />
    </aside>
  );
};

Message.propTypes = {
  close: PropTypes.func.isRequired,
  mode: PropTypes.oneOf(["Default", "Danger", "Highlight"]).isRequired,
  title: PropTypes.string,
  duration: PropTypes.number.isRequired
};

Message.defaultProps = {
  mode: "Default",
  initialAnimation: 0,
  finalAnimation: 0
};

export default Message;
