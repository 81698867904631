import actionTypes from "./actionTypes";
import getNestedShallowCopy from "../../utils/getnestedShallowCopy";

/*
State structure:
{
  locationId: {
    geometry: Polygon GJSON,
    name: String,
    description: String,
    importance: Number,
    isOnMap: Boolean,
    queriesTracker: Number,
    onlyCached: Boolean,
    isSaved: Boolean
    weatherArea: String
  }
}
 */
const initialState = {};

const reducer = (state = initialState, action) => {
  const { payload } = action;
  switch (action.type) {
    case actionTypes.ADD:
      return addLocations(state, payload);
    case actionTypes.SET_IS_SAVED:
      return setLocationProperty(state, "isSaved", payload);
    case actionTypes.SET_ON_MAP:
      return setLocationProperty(state, "isOnMap", payload);
    case actionTypes.SET_QUERY_TRACKER:
      return setLocationProperty(state, "queriesTracker", payload);
    case actionTypes.SET_ONLY_CACHED:
      return setLocationProperty(state, "onlyCached", payload);
    case actionTypes.SET_WEATHER_AREA:
      return setLocationProperty(state, "weatherAreaId", payload);
    case actionTypes.DELETE_LOCATION:
      return getNestedShallowCopy(state, {
        path: "locationId",
        dynamicProperties: { locationId: payload }
      });
    default:
      return state;
  }
};

const addLocations = (state, payload) => {
  const newLocations = [].concat(payload).reduce((acc, location) => {
    const { id, ...rest } = location;
    acc[id] = rest;
    return acc;
  }, {});
  //NOTE: Important to spread the state after newLocations, to remove new locations that are already in the state
  return { ...newLocations, ...state };
};

const setLocationProperty = (state, propToUpdate, payload) => {
  const { locationId } = payload;
  const dynamicProperties = { locationId, propToUpdate };
  return getNestedShallowCopy(state, {
    path: "locationId.propToUpdate",
    payload: payload[propToUpdate],
    dynamicProperties
  });
};

export default reducer;
