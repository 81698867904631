import React, { Suspense, lazy } from "react";
import PropTypes from "prop-types";
import Spinner from "../UI/Spinner";
const iconsMap = {
  "01d": "01",
  "01n": "01",
  "02d": "02",
  "02n": "02",
  "03d": "03",
  "03n": "03",
  "04d": "03",
  "04n": "03",
  "09d": "09",
  "09n": "09",
  "10d": "09",
  "10n": "09",
  "11d": "11",
  "11n": "11",
  "13d": "13",
  "13n": "13",
  "50d": "50",
  "50n": "50"
};

const WeatherIcon = ({ iconType, ...restProps }) => {
  const spinner = <Spinner {...restProps} />;
  if (!iconType) return spinner;
  const Icon = lazy(() => import(`./Icons/${iconsMap[iconType]}`));
  const iconProps = { ...restProps };
  delete iconProps.invColor;
  return (
    <Suspense fallback={spinner}>
      <Icon {...iconProps} />
    </Suspense>
  );
};

WeatherIcon.propTypes = {
  iconType: PropTypes.oneOf([
    "01d",
    "01n",
    "02d",
    "02n",
    "03d",
    "03n",
    "04d",
    "04n",
    "09d",
    "09n",
    "10d",
    "10n",
    "11d",
    "11n",
    "13d",
    "13n",
    "50d",
    "50n",
    ""
  ]),
  height: PropTypes.number.isRequired,
  width: PropTypes.number.isRequired,
  x: PropTypes.number,
  y: PropTypes.number
};

export default WeatherIcon;
