import React from 'react';
import PropTypes from 'prop-types';
import styles from './ScreenLoader.module.css';

const ScreenLoader = ({ className, style, circle }) => {
  const classes = [styles.Container];
  if (className) classes.push(className);
  return (
    <div
      style={style}
      className={classes.join(' ')}>
      <div
      style={{ borderRadius: circle ? '50%' : '10px' }}
      className={styles.Inner}></div>
    </div>
  )
}

ScreenLoader.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  circle: PropTypes.bool
}

ScreenLoader.defaultProps = {
  circle: false
}

export default ScreenLoader;
