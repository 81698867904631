import { getDayTimeZero } from "../utils/timeFunctions";
//Locations
export const sortAndMapLocations = raw => {
  const rawLocations = [].concat(raw);
  const sortBy = rawLocations.some(
    val => val.score === undefined || val.score === null
  )
    ? "importance"
    : "score";
  return rawLocations
    .sort((a, b) => b[sortBy] - a[sortBy])
    .map(rawLocation =>
      mapLocation(rawLocation, {
        queriesTracker: 0,
        onlyCached: true,
        isOnMap: false,
        weatherAreaId: null,
        isSaved: false
      })
    );
};

export const mapLocation = (rawLocation, additional) => ({
  ...additional,
  name: rawLocation.name,
  geometry: rawLocation.geometry,
  description: rawLocation.displayName,
  importance: Number(rawLocation.importance),
  id: rawLocation.id
});

//WeatherAreas
export const mapWeatherArea = (rawWA, additional = {}) => {
  let { updatedAt, isLoading, rest } = additional;
  isLoading = isLoading || false;
  updatedAt = updatedAt || new Date();
  return {
    ...rest,
    isLoading,
    updatedAt,
    geometry: rawWA.geometry,
    days: mapWeatherList(rawWA.list),
    id: rawWA.id
  };
};

const mapWeatherList = list => {
  return list.reduce((acc, day) => {
    let result = { ...acc };
    const date = getDayTimeZero(day.dt);
    const key = date.toISOString();
    result[key] = {
      date,
      sunrise: new Date(day.sunrise),
      sunset: new Date(day.sunset),
      clouds: Number(day.clouds) || 0, //%
      rain: roundTwoDecimals(day.rain) || 0, //mm
      snow: roundTwoDecimals(day.snow) || 0, //mm
      temp: {
        //Conversion from Kelvin to Celsius
        avg: roundTwoDecimals(day.temp.avg - 273.15), //ºC
        feelsLike: roundTwoDecimals(day.temp.feelsLike - 273.15), //ºC
        min: roundTwoDecimals(day.temp.min - 273.15), //ºC
        max: roundTwoDecimals(day.temp.max - 273.15) //ºC
      },
      wind: {
        //Speec conversion from meter/sec to km/hour
        speed: roundTwoDecimals((day.wind?.speed * 1000) / 3600) || 0, //km/h
        deg: Math.round(day.wind?.deg) || 0 //degrees [0,360)
      },
      main: {
        main: day.main[0].main,
        description: day.main[0].description,
        icon: day.main[0].icon
      }
    };
    return result;
  }, {});
};

const roundTwoDecimals = num => {
  return Math.round(num * 100) / 100;
};
