import React, { useState, useEffect, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import Backdrop from '../UI/Backdrop';
import Switcher from '..//UI/Buttons/MutableButton';
import LocManager from '../../containers/LocManager';
import Search from '../Search';
import CitiesList from '../CitiesList';
import LocsInfo from '../../containers/LocsInfo';
import styles from './SideDrawer.module.css';

const SideDrawer = (props) => {
  const mq = useMemo(() => window.matchMedia(`(min-width: 40rem)`),[]);
  const [ wideViewPort, setWideViewPort ] = useState(mq.matches)
  const [ isVisible, showSideDrawer ] = useState(mq.matches);

  const onResize = useCallback(() => {
    setWideViewPort(mq.matches);
    if (mq.matches) {
      showSideDrawer(true);
    }
  },[mq]);

  useEffect(() => {
    window.addEventListener('resize', onResize);
    return _ => window.removeEventListener('resize', onResize);
  },[onResize]);

  const toggleVisible = () => {
    showSideDrawer(prev => wideViewPort ? true : !prev)
  }

  const containerClasses = [styles.Container, props.className];
  const switcherClasses = [styles.Switcher];
  if (isVisible) {
    switcherClasses.push(styles.SwitcherDrawerOpened);
  } else {
    containerClasses.push(styles.SideDrawerClosed);
  }
  return (
    <>
      <Backdrop show={!wideViewPort && isVisible} onClick={toggleVisible} />
      <Switcher
        onAction={toggleVisible}
        className={switcherClasses.join(' ')}
        mode={isVisible ? 'close' : 'burger'}/>
      <aside
        onFocus={() => showSideDrawer(true)}
        className={containerClasses.join(' ')}
        >
        <LocManager className={styles.Controls}>
          {(parentProps) => (<>
            <Search
              className={styles.Search}
              onSearch={parentProps.handleSearch}
              onSubmit={parentProps.handleSubmit}
              data={parentProps.searchResults}
              searching={parentProps.searching}
            />
            <CitiesList
              className={styles.CitiesList}
              toggleOnMap={parentProps.toggleLocationOnMap}
              removeLocation={parentProps.removeLocation}
              toggleSaved={parentProps.toggleLocationSaved}
              onSelect={parentProps.onSelectLocation}
              data={parentProps.locations}
            />
            {parentProps.selectedLocationName && <LocsInfo
              className={styles.LocsInfo}
              locationNames={parentProps.selectedLocationName}
              id={parentProps.selectedWeatherAreaId}
            />}
          </>)}
        </LocManager>
      </aside>
    </>
  );
};

SideDrawer.propTypes = {
  className: PropTypes.string
}

export default SideDrawer;
