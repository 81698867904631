//FIX children styles shouldn have margin defined
import React from "react";
import PropTypes from "prop-types";
import DayInfo from "./DayInfo";
import DaysInfo from "./DaysInfo";
import ScreenLoader from "../UI/ScreenLoader";
import pickFromObject from "../../utils/pickFromObject";
import styles from "./WeatherInfo.module.css";

const WeatherInfo = ({ data, daySelected, className, style }) => {
  const containersClasses = [styles.Containers];
  if (className) {
    containersClasses.unshift(className);
  }
  if (!data || !daySelected) {
    //Screen loaders: 1 = circle, 0 = box
    const screenLoaders = [
      1,
      1,
      1,
      1,
      1,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      1
    ].map((l, i) => (
      <ScreenLoader
        circle={l === 1 ? true : false}
        key={`Grid-loader-${i}`}
        className={styles[`GridItem-${i}`]}
      />
    ));
    const loadersContainerClasses = [
      ...containersClasses,
      styles.ScreenLoadersContainer
    ];
    return (
      <div className={loadersContainerClasses.join(" ")} style={style}>
        {screenLoaders}
      </div>
    );
  }
  const dayProps = pickFromObject(data[daySelected.toISOString()], [
    "sunset",
    "sunrise",
    "clouds",
    "temp",
    "wind",
    "main"
  ]);
  const daysProps = Object.values(data).map(day =>
    pickFromObject(day, ["date", "temp", "main", "rain", "snow"])
  );
  return (
    <>
      <DaysInfo
        className={containersClasses.join(" ")}
        style={style}
        data={daysProps}
        selected={daySelected}
      />
      <DayInfo
        className={containersClasses.join(" ")}
        style={style}
        data={dayProps}
      />
    </>
  );
};

WeatherInfo.propTypes = {
  data: PropTypes.objectOf(
    PropTypes.exact({
      date: PropTypes.instanceOf(Date).isRequired,
      sunrise: PropTypes.instanceOf(Date).isRequired,
      sunset: PropTypes.instanceOf(Date).isRequired,
      temp: PropTypes.exact({
        avg: PropTypes.number.isRequired,
        feelsLike: PropTypes.number.isRequired,
        min: PropTypes.number.isRequired,
        max: PropTypes.number.isRequired
      }).isRequired,
      wind: PropTypes.exact({
        speed: PropTypes.number.isRequired,
        deg: PropTypes.number.isRequired
      }),
      main: PropTypes.exact({
        main: PropTypes.string.isRequired,
        description: PropTypes.string.isRequired,
        icon: PropTypes.string.isRequired
      }),
      rain: PropTypes.number,
      snow: PropTypes.number,
      clouds: PropTypes.number
    })
  ),
  daySelected: PropTypes.instanceOf(Date),
  className: PropTypes.string,
  style: PropTypes.object
};

export default WeatherInfo;
