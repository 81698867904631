import React, { useRef, useEffect } from "react";
import PropTypes from "prop-types";
import { FaRegTrashAlt, FaRegBookmark } from "react-icons/fa";
import { WiDayRainMix } from "react-icons/wi";
import Button from "../../UI/Buttons/BasicButton";
import Spinner from "../../UI/Spinner";
import withUserInput from "../../../hoc/withUserInput";
import blurOnTree from "../../../utils/blurOnTree";
import styles from "./CityItem.module.css";

const CityItem = props => {
  const {
    city,
    toggleSaved,
    toggleOnMap,
    removeLocation,
    onSelect,
    selected,
    style,
    className
  } = props;
  const { id, name, desc, isSaved, isOnMap, weatherAreaId } = city;
  const containerRef = useRef();

  // When selected gets focus.
  // NOTE, Expected behaviour: On blur doesn't remove selected flag
  useEffect(() => {
    if (selected && blurOnTree(containerRef.current, document.activeElement)) {
      containerRef.current.focus();
    }
  }, [selected]);

  const onSelectItem = () => {
    // If already selected do nothing
    if (selected) return;
    onSelect(id);
  };

  // Button: Save Bookmark/Remove Bookmark
  let saveBtnTitle = "Bookmark location";
  const saveBtnStyles = [styles.Button, styles.ButtonDefault];
  if (isSaved) {
    saveBtnTitle = "Remove from bookmarks";
    saveBtnStyles.splice(1, 1, styles.ButtonActive);
  }
  const saveBtn = (
    <Button
      styles={saveBtnStyles.join(" ")}
      stylesActive={styles.Active}
      onAction={() => toggleSaved(id)}
      title={saveBtnTitle}
    >
      <FaRegBookmark />
    </Button>
  );

  // Button: Show on Map/Hide on map
  let onMapBtnTitle = "Show on Map";
  const onMapBtnStyles = [styles.Button, styles.ButtonDefault];
  if (isOnMap) {
    onMapBtnTitle = "Remove from Map";
    onMapBtnStyles.splice(1, 1, styles.ButtonActive);
  }
  const mapBtn = (
    <Button
      styles={onMapBtnStyles.join(" ")}
      stylesActive={styles.Active}
      onAction={() => toggleOnMap(id)}
      title={onMapBtnTitle}
    >
      <WiDayRainMix />
    </Button>
  );

  // Button: Remove location
  const removeBtn = (
    <Button
      styles={[styles.Button, styles.ButtonDanger].join(" ")}
      stylesActive={styles.Active}
      onAction={() => removeLocation(id)}
      title="Delete location"
    >
      <FaRegTrashAlt />
    </Button>
  );

  //Container styles
  const containerClasses = [styles.ListItem];
  if (selected) {
    containerClasses.push(styles.ItemSelected);
  }
  if (className) {
    containerClasses.unshift(className);
  }

  //Spinner
  const showSpinner =
    weatherAreaId === "" ? <Spinner className={styles.Spinner} /> : null;

  return (
    <li
      ref={containerRef}
      className={containerClasses.join(" ")}
      onFocus={onSelectItem}
      tabIndex="0"
      style={style}
    >
      {saveBtn}
      {showSpinner || mapBtn}
      <span className={styles.Text} title={desc}>
        {name}
      </span>
      {removeBtn}
    </li>
  );
};

CityItem.propTypes = {
  city: PropTypes.exact({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    weatherAreaId: PropTypes.string,
    desc: PropTypes.string,
    isOnMap: PropTypes.bool,
    isSaved: PropTypes.bool
  }).isRequired,
  toggleOnMap: PropTypes.func.isRequired,
  toggleSaved: PropTypes.func.isRequired,
  removeLocation: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
  selected: PropTypes.bool,
  style: PropTypes.object,
  className: PropTypes.string
};

CityItem.defaultProps = {
  selected: false
};

const removeLocationConfirmation = {
  description: "Would you like to remove the Location?",
  cbName: "removeLocation",
  tooltip: "Remove location",
  format: "Danger",
  text: "Remove"
};

/* NOTE: bookmarks feature not ready */
// const toggleSavedConfirmation = {
//   description: "Only Bookmarked locations will remain after close the session!",
//   cbName: "toggleSaved",
//   tooltip: "Remove location from bookmarks",
//   format: "Default",
//   text: "Remove Bookmark",
//   inputRequired: (args, props) => props.city.isSaved || false
// };

const warningFeauteNotready = {
  description: "Feature not available yet, at the moment bookmarks do nothing",
  cbName: "toggleSaved",
  format: "Danger",
  text: "Close"
};

export default withUserInput(
  CityItem,
  removeLocationConfirmation,
  warningFeauteNotready
);
