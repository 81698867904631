import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { actions } from "../../store";

const useMessages = () => {
  const dispatch = useDispatch();
  return useCallback(
    (message, duration = 10000, type = "Default") => {
      dispatch(actions.messages.add(message, type, duration));
    },
    [dispatch]
  );
};

export default useMessages;
