import { useReducer, useCallback, useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import getUniqueId from "../../utils/uniqueId";
import { actions } from "../../store";

const at = {
  ADD_ERROR_ID: "ADD_ERROR_ID",
  REMOVE_ERROR_ID: "REMOVE_ERROR_ID"
};
const initialState = [];
const errorsReducer = (state, action) => {
  const { type, payload } = action;
  switch (type) {
    case at.ADD_ERROR_ID:
      if (state.includes(payload)) return state;
      return [...state, payload];
    case at.REMOVE_ERROR_ID:
      return state.filter(val => val !== payload);
    default:
      return state;
  }
};

//NOTE: Closure can be expanded with more resolving methods
const resolvingActions = (error, dispatch) => {
  const { message } = error;
  const resolvingMethods = {
    sendMessage(customMsg = message, duration = 10000) {
      dispatch(actions.messages.add(customMsg, "Danger", duration));
      return resolvingMethods;
    }
  };
  return resolvingMethods;
};

const useErrorsListener = () => {
  const [trackingErrors, localDispatch] = useReducer(
    errorsReducer,
    initialState
  );
  const globalErrors = useSelector(store => store.errors);
  const [errors, setErrors] = useState({});
  const prevErrorsIds = useRef([]);

  useEffect(() => {
    let update = false;
    const currentErrors = {};
    trackingErrors.forEach(errorId => {
      if (globalErrors[errorId]) {
        currentErrors[errorId] = globalErrors[errorId];
      }
      if (!prevErrorsIds.current.includes(errorId)) {
        update = true;
      }
    });
    if (prevErrorsIds.current.length !== Object.keys(currentErrors).length) {
      update = true;
    }
    if (update) {
      prevErrorsIds.current = Object.keys(currentErrors);
      setErrors(currentErrors);
    }
  }, [trackingErrors, globalErrors]);

  const dispatch = useDispatch();
  const tryDispatch = useCallback(
    (actionGenerator, ...payload) => {
      const errorId = getUniqueId("Error");
      localDispatch({ type: at.ADD_ERROR_ID, payload: errorId });
      dispatch(actionGenerator(...payload, errorId));
      return errorId;
    },
    [dispatch]
  );

  const resolve = useCallback(
    errorId => {
      localDispatch({ type: at.REMOVE_ERROR_ID, payload: errorId });
      if (errors[errorId]) {
        dispatch(actions.errors.setResolved(errorId));
        return resolvingActions(errors[errorId], dispatch);
      }
    },
    [dispatch, errors]
  );
  return { errors, tryDispatch, resolve };
};

export default useErrorsListener;
