import React, { useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { createSelector } from "reselect";
import useErrorListener from "../../hooks/errorsListener";
import { getCentroid, contains } from "../../utils/geometryHelpers";
import getRelativeIndex from "../../utils/getRelativeIndex";
import generateId from "../../utils/uniqueId";
import { actions } from "../../store";

const selectWeatherAreas = store => store.weatherAreas;
const selectLocations = store => store.locations;

const getSortedDays = weatherAreas =>
  Object.keys(weatherAreas)
    .reduce((acc, waKey) => {
      const days = Object.keys(weatherAreas[waKey].days)
        .map(day => Number(new Date(day).valueOf()))
        .filter(day => !acc.includes(day));
      return [...acc, ...days];
    }, [])
    .sort((a, b) => a - b);

const getLocationsOnMap = locations =>
  Object.keys(locations).reduce((acc, locKey) => {
    const loc = locations[locKey];
    const result = [...acc];
    if (loc.isOnMap) {
      result.push(loc);
    }
    return result;
  }, []);

const getWeatherAreasOnMap = (locsOnMap, was) => {
  const waOnMapKeys = locsOnMap.reduce((acc, loc) => {
    const result = [...acc];
    if (loc.weatherAreaId && !acc.includes(loc.weatherAreaId)) {
      result.push(loc.weatherAreaId);
    }
    return result;
  }, []);
  return waOnMapKeys.reduce((acc, key) => {
    const result = { ...acc };
    result[key] = { ...was[key] };
    return result;
  }, {});
};

const selectSortedDates = createSelector(selectWeatherAreas, getSortedDays);

const selectLocationsOnMap = createSelector(selectLocations, getLocationsOnMap);

const selectWeatherAreasOnMap = createSelector(
  selectLocationsOnMap,
  selectWeatherAreas,
  getWeatherAreasOnMap
);
const MapFrame = ({ className, style, children }) => {
  const { errors, tryDispatch, resolve } = useErrorListener();

  /* Local State */
  const [selectedDate, setSelectedDate] = useState(null);

  // [{ errorId, coords: [lat, lon] }]
  const [pointsLoadingCoords, setPointsLoadingCoords] = useState([]);

  //State from store
  const sortedDates = useSelector(selectSortedDates);
  const weatherAreasOnMap = useSelector(selectWeatherAreasOnMap);
  const isAnyWALoading = useSelector(store => {
    const wa = selectWeatherAreasOnMap(store);
    return Object.keys(wa).some(waKey => wa[waKey].isLoading);
  });
  const locations = useSelector(selectLocationsOnMap);
  const isAnyLocation = locations.length > 0;
  // Dates
  //Init selectedDate && prevent out of limits if sortedDates changes
  useEffect(() => {
    if (!sortedDates[0] || !isAnyLocation) {
      setSelectedDate(null);
    } else if (!selectedDate) {
      setSelectedDate(new Date(sortedDates[0]));
    } else if (selectedDate.valueOf() < sortedDates[0]) {
      setSelectedDate(new Date(sortedDates[0]));
    } else if (selectedDate.valueOf() > sortedDates.slice(-1)) {
      setSelectedDate(new Date(sortedDates.slice(-1)));
    }
  }, [selectedDate, sortedDates, isAnyLocation]);

  const dayHandler = relPos => {
    const currentI = sortedDates.findIndex(d => d === selectedDate.valueOf());
    const i = getRelativeIndex(currentI, relPos, sortedDates.length);
    setSelectedDate(new Date(sortedDates[i]));
  };

  let datesRange;
  if (!isAnyWALoading && selectedDate) {
    datesRange = {
      min: new Date(sortedDates[0]),
      max: new Date(sortedDates[sortedDates.length - 1]),
      current: selectedDate
    };
    // If no locations DateHandler shows today's date with both buttons disabled
  } else if (!isAnyLocation) {
    const today = new Date(new Date().setUTCHours(0, 0, 0, 0));
    datesRange = {
      min: today,
      max: today,
      current: today
    };
  }

  const onAction = useCallback(
    coords => {
      const errorId = tryDispatch(actions.showPointData, ...coords);
      setPointsLoadingCoords(prev => [...prev, { errorId, coords }]);
    },
    [tryDispatch]
  );

  /* Icons */
  const locationsToIcons = locations.reduce((acc, loc) => {
    const result = [...acc];
    const { geometry, weatherAreaId: waId, name } = loc;
    //If no weatherArea assigned to location skip it
    if (!waId) return result;
    const groupIndex = result.findIndex(icon => icon.iconId === waId);
    if (groupIndex >= 0) {
      const { locationNames } = result[groupIndex];
      locationNames.push(name);
      result[groupIndex] = {
        ...result[groupIndex],
        locationNames,
        // geometry is calculated only once
        geometry:
          locationNames.length === 2
            ? {
                type: "Point",
                coordinates: getCentroid(weatherAreasOnMap[waId].geometry)
              }
            : result[groupIndex].geometry
      };
    } else {
      const selectedDayWeather =
        weatherAreasOnMap[waId].days[selectedDate?.toISOString()];
      let iconType = "";
      if (selectedDayWeather?.main.icon && !weatherAreasOnMap[waId].isLoading) {
        iconType = selectedDayWeather?.main.icon;
      }
      result.push({
        geometry: {
          type: "Point",
          coordinates: getCentroid(geometry)
        },
        weatherAreaId: weatherAreasOnMap[waId].isLoading ? "" : waId,
        iconType,
        locationNames: [name],
        iconId: waId
      });
    }
    return result;
  }, []);

  /* Add loading Points */
  const loadingPoints = pointsLoadingCoords.map(({ coords }) => ({
    geometry: { type: "Point", coordinates: coords },
    iconId: generateId("loading-point-data"),
    weatherAreaId: "",
    iconType: "",
    locationNames: ""
  }));

  /* Removing loading points */
  useEffect(() => {
    const waPolygons = Object.values(weatherAreasOnMap).map(wa => wa.geometry);
    const currentErrorIds = Object.keys(errors);
    const remainingPoints = [];
    let update = false;
    pointsLoadingCoords.forEach(point => {
      const { errorId, coords } = point;
      if (waPolygons.some(polygon => contains(polygon, coords))) {
        resolve(errorId);
        update = true;
      } else if (currentErrorIds.includes(errorId)) {
        resolve(errorId).sendMessage();
        update = true;
      } else {
        remainingPoints.push(point);
      }
    });
    if (update) setPointsLoadingCoords(remainingPoints);
  }, [resolve, pointsLoadingCoords, weatherAreasOnMap, errors]);

  const icons = [...locationsToIcons, ...loadingPoints];

  return (
    <main className={className} style={style}>
      {children({
        dayHandler,
        datesRange,
        selectedDate,
        onAction,
        icons
      })}
    </main>
  );
};

MapFrame.propTypes = {
  children: PropTypes.func.isRequired,
  style: PropTypes.object,
  className: PropTypes.string
};

export default MapFrame;
