import React from 'react';
import styles from './Spinner.module.css';
const Spinner = (props) => {
  const wheelStyles = [styles.WheelSvg];
  if (props.invColor) {
    wheelStyles.push(styles.SecondaryColor);
  } else {
    wheelStyles.push(styles.PrimaryColor);
  }
  return (
    <svg
      viewBox="0 0 100 100"
      x={props.x}
      y={props.y}
      width={props.width}
      height={props.height}
      className={props.className}
    >
      <circle
        cx="50"
        cy="50"
        r="20"
        className={wheelStyles.join(' ')}
      />
    </svg>
  )
}

export default Spinner;
