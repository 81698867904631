import actionTypes from "./actionTypes";
import getNestedShallowCopy from "../../utils/getnestedShallowCopy";
/*
State structure:
{
  weatherAreaId: {
    updatedAt: Date
    isLoading: Boolean
    geometry: Polygon GJSON
    days: {
      dateISOString: {
        date: Date
        sunrise: Date
        sunset: Date
        main: {
          main: String
          description: String
          icon: String, Enum: [ '01d', '01n', '02d', '02n', '03d', '03n', '04d', '04n', '09d', '09n', '10d', '10n', '11d', '11n', '13d', '13n', '50d', '50n']
        }
        temp: {
          avg: Number
          feesLike: Number
          min: Number
          max: Number
        }
        clouds: (Optional) String
        rain: (Optional) String
        snow: (Optional) String
        wind: (Optional) {
          speed: Number
          deg: Number Interval [0,360)
        }
      }
    }
  }
}
 */
const initialState = {};

const reducer = (state = initialState, action) => {
  const { payload } = action;
  switch (action.type) {
    case actionTypes.ADD_NEW:
      return addNewWeatherArea(state, payload);
    case actionTypes.SET_LOADING:
      return setWAProperty(state, "isLoading", payload);
    case actionTypes.UPDATE_WEATHER:
      return updateWeather(state, payload);
    default:
      return state;
  }
};

const addNewWeatherArea = (state, payload) => {
  const { id, ...weatherArea } = payload;
  if (state[id]) return state; //If weatherArea exists do nothing
  const newState = { ...state };
  newState[id] = weatherArea;
  return newState;
};

const setWAProperty = (state, propToUpdate, payload) => {
  const { weatherAreaId } = payload;
  const dynamicProperties = { weatherAreaId, propToUpdate };
  return getNestedShallowCopy(state, {
    path: "weatherAreaId.propToUpdate",
    payload: payload[propToUpdate],
    dynamicProperties
  });
};

const updateWeather = (state, payload) => {
  const { id, updatedAt, days } = payload;
  const dynamicProperties = { weatherAreaId: id };
  return getNestedShallowCopy(state, [
    {
      path: "weatherAreaId.days",
      payload: days,
      dynamicProperties
    },
    {
      path: "weatherAreaId.updatedAt",
      payload: updatedAt || new Date(),
      dynamicProperties
    }
  ]);
};

export default reducer;
