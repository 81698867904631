import actionTypes from "./actionTypes";
import reducer from "./reducer";
import thunks from "./thunks";
import locs from "../locations";
import errors from "../errors";

/* Return actions */
const addQuery = query => ({
  type: actionTypes.ADD_QUERY,
  payload: query
});

const addLocationsList = (query, locationIds) => ({
  type: actionTypes.ADD_LOCATIONS_LIST,
  payload: { query, locationIds }
});
const bumpQuery = query => ({
  type: actionTypes.BUMP_QUERY,
  payload: query
});
const deleteQuery = query => ({
  type: actionTypes.DELETE_QUERY,
  payload: query
});

/* Action creators that return a thunk */
const searchLocations = (query, errorTracker) => (dispatch, getState) =>
  thunks.searchingLocations(
    dispatch,
    getState,
    query,
    {
      addQuery,
      removeQuery,
      setLocations,
      removeLastQuery,
      bumpQuery,
      addError: errors.actions.add
    },
    errorTracker
  );

const setLocations = (query, locations, errorTracker) => (dispatch, getState) =>
  thunks.settingLocations(
    dispatch,
    getState,
    { query, locations },
    {
      addLocations: locs.actions.add,
      addLocationsList,
      updateLocationsQueryTrackers: locs.actions.updateQueryTrackers
    },
    errorTracker
  );

const removeQuery = (query, errorTracker) => (dispatch, getState) => {
  thunks.removingQuery(
    dispatch,
    getState,
    query,
    {
      updateLocationsQueryTrackers: locs.actions.updateQueryTrackers,
      deleteQuery
    },
    errorTracker
  );
};

const removeLastQuery = errorTracker => (dispatch, getState) => {
  thunks.removingLastQuery(dispatch, getState, { removeQuery }, errorTracker);
};

const queries = {
  actions: {
    addQuery,
    addLocationsList,
    removeLastQuery,
    bumpQuery,
    deleteQuery,
    searchLocations,
    setLocations,
    removeQuery
  },
  reducer
};

export default queries;
