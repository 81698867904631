/**
 * HOC component that returns the wrapped component and renders a modal on top
 * of it. When the indicated prop that must hold a function is called from
 * the wrapped component, it renders a modal to ask the user to continue
 * the action
 *
 * The most common use case is to create a confirmation message before execute
 * certain tasks
 *
 * @param { React compoent } WrappedComponent Required
 * @param { Object or Array of objects } controls (Required at least one) Config
 * objects for every control in the modal. It needs the following properties:
 *    { String or JSX } description (Required) Renders in the
 *    modal showing extra information to the user
 *    { String } cbName (Required) Name of the prop that holds a function.
 *    When called in the wapped component triggers the modal and only gets
 *    executed if user interacts with the control.
 *    { String } tooltip (Optional) Description of the control
 *    { Enum } format (Optional) Indicates specific format of the control
 *    { Boolean } closeControl (Optional) Indicates if modal will render a
 *    control to close it without any further action.
 *    { String  } text: (Required) Thext that is shown in the control,
 *    { function } inputRequired: (Optional) Function that receives 2
 *     params:
 *       @param: { Array } cbArgs with arguments passed to the callback.
 *       @param: { Object } props with props without modifications
 *     It must return a boolean. If false the modal won't render and the
 *     callback will be executed straight away.
 */

import React, { useState } from "react";
import Modal from "../../components/UI/Modal";
import Question from "../../components/Question";

const withUserInput = (WrappedComponent, ...rest) => {
  const controls = rest.flat();

  //Wrapper component
  const Wrapper = props => {
    const [control, setControl] = useState(null);

    //Set child props changing the pointer of mapped props
    const mappedProps = controls.reduce((acc, ctrl) => {
      if (props[ctrl.cbName]) {
        acc[ctrl.cbName] = (...args) => setModal(ctrl.cbName, args);
      }
      return acc;
    }, {});
    const childProps = { ...props, ...mappedProps };

    //Open the modal and set the controls
    const setModal = (cbName, args) => {
      const newControl = controls.find(ctrl => ctrl.cbName === cbName);
      if (newControl.inputRequired && !newControl.inputRequired(args, props)) {
        return props[cbName](...args);
      }
      newControl.args = args;
      setControl(newControl);
    };

    // Modal layout
    let modal;
    if (control) {
      const {
        args,
        format,
        tooltip,
        text,
        cbName,
        description,
        closeControl
      } = control;

      // Set the callback control
      const controls = [
        {
          onAction: () => {
            props[cbName](...args);
            setControl(null);
          },
          text: text,
          format: format || "Default",
          tooltip: tooltip || text
        }
      ];

      // If required Set closeControl
      if (closeControl) {
        controls.unshift({
          onAction: () => setControl(null),
          text: "Cancel",
          format: "Default",
          tooltip: "Cancel action"
        });
      }

      // Set the modal
      modal = (
        <Modal show close={() => setControl(null)}>
          <Question options={controls}>{description}</Question>
        </Modal>
      );
    }

    return (
      <>
        <WrappedComponent {...childProps} />
        {modal}
      </>
    );
  };

  return Wrapper;
};

export default withUserInput;
