import backend from "../../backend";

const searchingLocations = (
  dispatch,
  getState,
  query,
  actions,
  errorTracker
) => {
  const { map, cache } = getState().queries;
  if (map[query]?.loading) return;
  const {
    addQuery,
    removeQuery,
    setLocations,
    removeLastQuery,
    addError,
    bumpQuery
  } = actions;
  if (cache.includes(query)) {
    dispatch(bumpQuery(query, errorTracker));
    return;
  }
  dispatch(addQuery(query, errorTracker));
  return backend
    .searchLocationByQuery(query)
    .then(locations => {
      dispatch(setLocations(query, locations, errorTracker));
      if (cache.length > 50) dispatch(removeLastQuery(errorTracker));
    })
    .catch(e => {
      dispatch(removeQuery(query, errorTracker));
      dispatch(
        addError({
          data: e.response?.data,
          type: "network",
          message: e.response
            ? "There was an error in the server, try it later"
            : "I can't reach the server. Internet connection? proxy? firewall?...",
          id: errorTracker
        })
      );
    });
};

const settingLocations = (
  dispatch,
  getState,
  payload,
  actions,
  errorTracker
) => {
  const {
    addLocationsList,
    addLocations,
    updateLocationsQueryTrackers
  } = actions;
  const { query, locations } = payload;
  const locIds = locations.map(loc => loc.id);
  if (locations.length > 0) {
    dispatch(addLocations(locations, errorTracker));
    dispatch(updateLocationsQueryTrackers(locIds, 1, errorTracker));
  }
  dispatch(addLocationsList(query, locIds, errorTracker));
};

const removingQuery = (dispatch, getState, query, actions, errorTracker) => {
  const { map } = getState().queries;
  const { updateLocationsQueryTrackers, deleteQuery } = actions;
  if (map[query]?.locations?.length > 0) {
    dispatch(
      updateLocationsQueryTrackers(map[query].locations, -1, errorTracker)
    );
  }
  dispatch(deleteQuery(query, errorTracker));
};

const removingLastQuery = (dispatch, getState, actions, errorTracker) => {
  const { removeQuery } = actions;
  const { cache } = getState().queries;
  dispatch(removeQuery(cache[0], errorTracker));
};

const thunks = {
  searchingLocations,
  settingLocations,
  removingQuery,
  removingLastQuery
};
export default thunks;
