import React, { useState, useRef, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import H2 from "../../components/UI/Headers/H2";
import WeatherInfo from "../../components/WeatherInfo";
import DateHandler from "../../components/DateHandler";
import Question from "../../components/Question";
import ScreenLoader from "../../components/UI/ScreenLoader";
import { useSelector } from "react-redux";
import { actions } from "../../store";
import { isToday } from "../../utils/timeFunctions";
import getRelativeIndex from "../../utils/getRelativeIndex";
import useErrorListener from "../../hooks/errorsListener";

const LocsInfo = ({ id, date, locationNames, className, style }) => {
  /* Errors handler */
  const { errors, tryDispatch, resolve } = useErrorListener();
  const errorTracker = useRef(null);
  const [error, setError] = useState(false);

  /* Local state */
  const [currentDate, setCurrentDate] = useState(null);

  /* Store state */
  const updateWeatherArea = useCallback(() => {
    const errorId = tryDispatch(actions.weatherAreas.update, id);
    errorTracker.current = { errorId, id };
  }, [id, tryDispatch]);
  const wa = useSelector(store => store.weatherAreas[id]);
  const isUpdated = isToday(wa?.updatedAt);
  const isLoading = wa?.isLoading;
  useEffect(() => {
    if (wa && !isUpdated && !isLoading && !error) {
      updateWeatherArea(id);
    }
  }, [wa, error, isUpdated, isLoading, id, updateWeatherArea]);

  const isWeatherReady = wa && isUpdated && !isLoading && !error;

  /* Headers */
  const header = [].concat(locationNames).map((loc, i) => {
    if (loc.length === 0) {
      return (
        <ScreenLoader
          key={`loading-${i}`}
          style={{ width: "75%", height: "1.5rem" }}
        />
      );
    }
    return <div key={loc}>{loc}</div>;
  });

  /* Dates */
  let dateHandlerDates, sortedPrimitiveDates;
  if (isWeatherReady) {
    sortedPrimitiveDates = Object.keys(wa.days)
      .sort((a, b) => Date.parse(a) - Date.parse(b))
      .map(dateStr => Date.parse(dateStr));
    dateHandlerDates = {
      min: new Date(sortedPrimitiveDates[0]),
      max: new Date(sortedPrimitiveDates.slice(-1).pop()),
      current: currentDate || new Date(sortedPrimitiveDates[0])
    };
  }

  const dateHandler = relPos => {
    let currentI = 0;
    if (currentDate) {
      currentI = sortedPrimitiveDates.indexOf(currentDate.valueOf());
    }
    const i = getRelativeIndex(currentI, relPos, sortedPrimitiveDates.length);
    setCurrentDate(new Date(sortedPrimitiveDates[i]));
  };
  const dateMarkup = (
    <DateHandler dates={dateHandlerDates} dayHandler={dateHandler} />
  );

  /* Weather */
  let weatherInfoDays, weatherInfoSelected;
  if (isWeatherReady) {
    weatherInfoDays = wa.days;
    weatherInfoSelected = currentDate || new Date(sortedPrimitiveDates[0]);
  }
  const weatherMarkup = (
    <WeatherInfo data={weatherInfoDays} daySelected={weatherInfoSelected} />
  );

  /* Errors */
  //Error handler
  useEffect(() => {
    Object.keys(errors).forEach(errId => {
      const { current } = errorTracker;
      const resolvingMethods = resolve(errId);
      if (current.errorId === errId && current.id === id) {
        setError(true);
        resolvingMethods.sendMessage();
      }
      errorTracker.current = null;
    });
  }, [errors, id, resolve]);

  useEffect(() => {
    setError(false);
  }, [id]);

  //Resolve error tracker if weather is ready
  if (isWeatherReady && errorTracker.current) {
    const { current } = errorTracker;
    resolve(current.errorId);
    errorTracker.current = null;
  }

  const updateError = () => {
    //updateWeatherArea();
    setError(false);
  };

  const errorMarkup = (
    <Question
      options={{
        text: "Try Again",
        onAction: updateError,
        tooltip: "Click to reload weather info"
      }}
    >
      The weather information is outdated. I couldn't get the weather data
    </Question>
  );
  return (
    <article className={className} stryle={style}>
      <H2 style={{ whiteSpace: "pre-wrap" }}>{header}</H2>
      {!error ? weatherMarkup : errorMarkup}
      {!error && !date ? dateMarkup : null}
    </article>
  );
};

LocsInfo.propTypes = {
  //id: WeatherArea Id
  id: PropTypes.string.isRequired,
  locationNames: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string.isRequired)
  ]).isRequired,
  date: PropTypes.instanceOf(Date),
  className: PropTypes.string,
  style: PropTypes.object
};

export default LocsInfo;
