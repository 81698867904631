import React, { useMemo, useCallback } from "react";
import PropTypes from "prop-types";
import { geoMercator, geoPath } from "d3-geo";
import styles from "./Map.module.css";
import topology from "../../assets/map.geo.json";
import WeatherIcon from "../WeatherIcon";
import LocsInfo from "../../containers/LocsInfo";
import { useTooltip, useTooltipInPortal } from "@visx/tooltip";

const Map = ({ className, icons, date, onAction }) => {
  const containerClasses = [styles.Container];
  if (className) {
    containerClasses.push(className);
  }

  // Map
  const projection = useMemo(() => geoMercator(), []);
  const path = useMemo(() => geoPath(projection), [projection]);
  const viewBox = useMemo(() => {
    const bounds = path.bounds(topology.features[0]);
    const initX = bounds[0][0];
    const initY = bounds[0][1];
    const width = bounds[1][0] - bounds[0][0];
    const height = bounds[1][1] - bounds[0][1];
    return {
      initX,
      initY,
      width,
      height,
      toString: () => `${initX} ${initY} ${width} ${height}`
    };
  }, [path]);

  //WeatherInfo Tooltip
  const {
    tooltipData,
    tooltipLeft,
    tooltipTop,
    tooltipOpen,
    showTooltip,
    hideTooltip
  } = useTooltip();

  const { containerRef, TooltipInPortal } = useTooltipInPortal({
    // use TooltipWithBounds
    detectBounds: true,
    // when tooltip containers are scrolled, this will correctly update the Tooltip position
    scroll: true
  });

  const openInfo = useCallback(
    (e, data) => {
      const dim = e.target.parentNode.ownerSVGElement.getBoundingClientRect();
      const x = e.clientX - dim.left;
      const y = e.clientY - dim.top;
      showTooltip({
        tooltipLeft: x,
        tooltipTop: y,
        tooltipData: data
      });
    },
    [showTooltip]
  );

  const closeInfo = () => {
    hideTooltip();
  };

  // Icons
  // TODO Refactor to improve performance, currently there are to many cases that cause fcn execution
  // TODO: Needs to be simplier and more clear
  const iconsElements = useMemo(() => {
    if (!icons) return;
    const xPositions = []; //Required by isIConClose
    const positionedIcons = icons.map((icon, i) => {
      const pos = path.centroid(icon.geometry); //TODO can be type point
      const { iconType, weatherAreaId, locationNames, iconId } = icon;
      xPositions.push({ x: pos[0], index: i });
      return { pos, iconType, weatherAreaId, locationNames, iconId };
    });
    xPositions.sort((a, b) => a.x - b.x);

    //Size of the icon is in function of viewbox width
    const iconSize = viewBox.width / 10;

    //Return distance (scalar) between to positions with X,Y coords
    const getDistance = (posA, posB) => {
      return Math.sqrt((posA[0] - posB[0]) ** 2 + (posA[1] - posB[1]) ** 2);
    };

    //Returns if icon is close to another icon in map
    const isIconClose = icon => {
      const index = xPositions.findIndex(ic => icon.pos[0] === ic.x);
      const posToCompare = index > 0 ? [index - 1] : [];
      for (let i = index + 1; i < xPositions.length; i++) {
        posToCompare.push(i);
      }
      const minDistance = iconSize;
      return posToCompare.some(pos => {
        const iconToCompare = positionedIcons[xPositions[pos].index];
        if (getDistance(icon.pos, iconToCompare.pos) <= minDistance) {
          return true;
        }
        return false;
      });
    };

    return positionedIcons.map(icon => {
      const { pos, weatherAreaId, locationNames, iconType, iconId } = icon;
      if (isIconClose(icon)) {
        return (
          <circle
            onMouseEnter={e => openInfo(e, { weatherAreaId, locationNames })}
            key={iconId}
            cx={pos[0]}
            cy={pos[1]}
            r={iconSize / 15}
            className={styles.Point}
          />
        );
      }
      return (
        <WeatherIcon
          key={iconId}
          onMouseEnter={e => openInfo(e, { weatherAreaId, locationNames })}
          iconType={iconType}
          x={pos[0] - iconSize / 2}
          y={pos[1] - iconSize / 2}
          width={iconSize}
          height={iconSize}
        />
      );
    });
  }, [viewBox, icons, path, openInfo]);

  //Handlers
  const onMapActionHandler = e => {
    const dim = e.currentTarget.getBoundingClientRect(); //SVG element
    const x = e.clientX - dim.left;
    const y = e.clientY - dim.top;
    //Map px to svg points
    const svgX = (x * viewBox.width) / dim.width + viewBox.initX;
    const svgY = (y * viewBox.height) / dim.height + viewBox.initY;
    const coords = projection.invert([svgX, svgY]);
    onAction(coords);
  };

  return (
    <figure className={containerClasses.join(" ")}>
      <div className={styles.KeepAspectRatio}>
        <svg
          ref={containerRef}
          className={styles.Image}
          title="Map of Ireland"
          viewBox={viewBox.toString()}
        >
          <g onClick={onMapActionHandler}>
            <path d={path(topology.features[0])} fill={"var(--color-land)"} />
            ))}
          </g>
          <g>{iconsElements}</g>
        </svg>
      </div>
      {tooltipOpen && (
        <TooltipInPortal
          className={styles.Tooltip}
          onMouseLeave={closeInfo}
          top={tooltipTop}
          left={tooltipLeft}
          offsetTop={-50}
          offsetLeft={-50}
          unstyled={true}
        >
          <LocsInfo
            date={date}
            id={tooltipData.weatherAreaId}
            locationNames={tooltipData.locationNames}
          />
        </TooltipInPortal>
      )}
    </figure>
  );
};

Map.propTypes = {
  icons: PropTypes.arrayOf(
    PropTypes.exact({
      geometry: PropTypes.exact({
        type: PropTypes.oneOf(["Point", "Polygon", "MultiPolygon"]).isRequired,
        coordinates: PropTypes.arrayOf(PropTypes.number).isRequired
      }).isRequired,
      weatherAreaId: PropTypes.string.isRequired,
      iconId: PropTypes.string.isRequired,
      locationNames: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.arrayOf(PropTypes.string.isRequired)
      ]).isRequired,
      iconType: PropTypes.string
    })
  ),
  date: PropTypes.instanceOf(Date),
  onAction: PropTypes.func.isRequired
};

export default Map;
