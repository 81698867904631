import actionTypes from "./actionTypes";

/*
State structure
[
  {
    message: String,
    type: Enum<String> ['Danger','Default','Highlight'],
    id: String,
    timerId: Returned value from setTimeout,
    duration: Number
  }
]
 */

const initialState = [];

const reducer = (state = initialState, action) => {
  const { payload } = action;
  switch (action.type) {
    case actionTypes.PUSH:
      return pushNewMessage(state, payload);
    case actionTypes.POP:
      return popMessage(state, payload);
    default:
      return state;
  }
};

const pushNewMessage = (state, payload) => {
  // If payload miss required properties error is not created
  if (!payload.message || !payload.id || !payload.timerId) {
    return state;
  }
  const { message, id, type, duration, timerId } = payload;
  return [
    ...state,
    {
      message,
      id,
      type: type || "Default",
      duration: duration || 5000,
      timerId
    }
  ];
};

const popMessage = (state, payload) => {
  return state.filter(msg => msg.id !== payload);
};

export default reducer;
