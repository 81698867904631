import actionTypes from "./actionTypes";
import reducer from "./reducer";

/* Action creators that return actions */
const add = error => {
  return {
    type: actionTypes.ADD,
    payload: error
  };
};

const setResolved = errorId => {
  return {
    type: actionTypes.SET_RESOLVED,
    payload: errorId
  };
};

const actions = { add, setResolved };

const errors = { actions, reducer };

export default errors;
