const updatingQueryTrackers = (
  dispatch,
  getState,
  payload,
  actions,
  errorTracker
) => {
  const { locationIds: locIdsArgument, addToTracker } = payload;
  const { setTracker, deleteLocation } = actions;
  const locations = getState().locations;
  //Location might be String or Array<String>;
  const locationIds = [].concat(locIdsArgument);
  locationIds.forEach(id => {
    const { onlyCached, queriesTracker } = locations[id];
    const newTrackerValue = queriesTracker + addToTracker;
    if (onlyCached && newTrackerValue <= 0) {
      dispatch(deleteLocation(id, errorTracker));
    } else {
      dispatch(setTracker(id, newTrackerValue, errorTracker));
    }
  });
};

const removingLocation = (dispatch, getState, id, actions, errorTracker) => {
  const location = getState().locations[id];
  if (location.queriesTracker === 0) {
    dispatch(actions.deleteLocation(id, errorTracker));
  } else {
    dispatch(actions.setOnlyCached(id, true, errorTracker));
  }
};

const usingLocation = (dispatch, getState, id, actions, errorTracker) => {
  const { setOnlyCached } = actions;
  dispatch(setOnlyCached(id, false, errorTracker));
};

const togglingWeather = (
  dispatch,
  getState,
  payload,
  actions,
  errorTracker
) => {
  const { locationId, isOnMap } = payload;
  const { setOnMap, getWeatherAreaId } = actions;
  const { weatherAreaId, geometry } = getState().locations[locationId];
  dispatch(setOnMap(locationId, isOnMap, errorTracker));
  if (!weatherAreaId) {
    dispatch(getWeatherAreaId(locationId, geometry, errorTracker));
  }
};

const thunks = {
  updatingQueryTrackers,
  removingLocation,
  usingLocation,
  togglingWeather
};

export default thunks;
